/*
 * File        : history.scss
 * Author      : AI2U (KHS)
 * Description : skin history page common css
*/

.history-page {
	&__menu {
		ion-segment-button {
			--indicator-height: 3px;
			border-bottom: solid 1px #e0e0e0;
		}
	}
	&__wrap {
		height: 100%;
		padding-top: 15px;

		.select-contents {
			ion-label {
				margin: 0;
				font-size: 14px;
			}
			.icon {
				margin: 0;
			}

			&__inner {
				display: flex;
				justify-content: space-between;
				background-color: #fff;

				&--compare {
					ion-item ion-button {
						height: 36px;
						--background: transparent;
						--border-radius: 18px;
						color: #282828;
						border: 1px solid #cdcdcd;
						border-radius: 20px;
						font-size: 13px;
						--background-hover: transparent;
						--background-focused: transparent;
						--background-activated: transparent;
					}
					ion-item ion-button::part(native) {
						padding: 0 15px;
					}

					ion-item ion-button.compare {
						--background: var(--ion-color-primary);
						border-color: transparent;
						color: var(--ion-color-primary-contrast);
					}
				}

				&--filter {
					display: flex;

					ion-item:first-child {
						margin-right: 8px;
					}
				}
			}
		}

		.none {
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			text-align: center;

			h2 {
				font-size: 20px;
				letter-spacing: -0.5px;
			}

			h3 {
				margin-top: 10px;
				font-size: 16px;
				letter-spacing: -0.4px;
				font-weight: normal;
			}
		}

		.list-contents {
			ion-list {
				padding-top: 20px;
			}

			.date {
				padding: 0 20px;
				margin: 0;
				font-size: 16px;
				font-weight: bold;
				letter-spacing: -0.4px;
			}

			.list-item {
				width: 100%;
				border-bottom: 1px solid #e0e0e0;
				--background-hover: transparent;

				&__inner {
					position: relative;
					width: 100%;
					display: flex;
					align-items: center;
					padding: 20px;
					cursor: pointer;
				}

				ion-checkbox {
					width: 24px;
					height: 24px;
					margin-inline: 0;
					margin-left: 20px;
				}

				ion-avatar {
					width: 80px;
					height: 80px;
					margin-right: 16px;
					--border-radius: 8px;
				}

				ion-icon {
					position: absolute;
					right: 20px;
					width: 24px;
					height: 24px;
					margin: 0 0 0 10px;
				}

				h3 {
					font-size: 14px;
					letter-spacing: -0.35px;
					margin-bottom: 4px;
				}

				.item-text {
					display: flex;
				}

				h2 {
					font-size: 16px;
					font-weight: bold;
					letter-spacing: -0.4px;
				}
			}

			.list-more {
				padding: 0;
			}
		}

		.bottom-contents {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			.btn-bottom {
				margin-inline: var(--pc-padding-horizontal);
				padding: 16px 20px;

				button {
					box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
				}
				.button {
					background-color: #bbbbbb;
				}
				.active {
					background-color: #44bbcd;
				}
			}
		}
	}
	.padding {
		padding-bottom: 80px;
	}
}

// change analysis guide
.guide-change {
	.action-sheet-group.sc-ion-action-sheet-ios {
		position: relative;
		height: 670px;
		padding: 36px 0 0 0;
		margin: 0;
		border-radius: 13px 13px 0 0;
		background: #fff;

		&::after {
			content: "";
			display: inline-block;
			width: calc(100% - 10px);
			height: 100%;
			margin-top: 40px;
			background-image: url(../img/guide-change_img.png);
			background-size: contain;
			background-repeat: no-repeat;
		}

		.close {
			width: 24px;
			height: 24px;
			position: absolute;
			top: 20px;
			right: 20px;
			background: url(../img/icons/icon-close.svg) center center no-repeat;
		}
	}

	.action-sheet-title {
		max-width: 231px;
		margin: 0 auto;
		white-space: break-spaces;
		padding: 0;
		background: #fff;
		letter-spacing: -0.45px;
		font-weight: bold;
		font-size: 18px;
		color: #282828;
	}
}

@media screen and (min-width: 768px) {
	.history-page .menu-wrap {
		margin-inline: var(--pc-padding-horizontal);
	}
}

@media screen and (max-width: 768px) {
}

@media screen and (max-width: 767px) {
	.history-page__wrap .bottom-contents .btn-bottom {
		margin-inline: var(--mobile-padding-horizontal);
	}
	
	.guide-change .action-sheet-container {
		padding: 0;
	}
	.guide-change .action-sheet-group.sc-ion-action-sheet-ios {
		height: 570px;
		overflow: hidden;
	}
}

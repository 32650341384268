/*
 * File        : video.scss
 * Description : video page css
*/

.pg-skin-video {
	.video-item {
		.bookmark-area {
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			right: 0;
		}
		.bookmark-area.ad {
			top: 0;
			cursor: initial;
			transform: inherit;
		}
	}
}

.video-item {
	border-bottom: solid 1px #e0e0e0;
	height: 120px;
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	cursor: pointer;
	&:last-child {
		border-bottom: none;
	}
	.label-icon {
		margin-bottom: 60px;
	}
	.label-icon ion-label {
		margin: 0;
	}

	ion-avatar,
	ion-avatar img {
		border-radius: 10px;
		height: 80px;
		width: 130px;
	}
}

ion-button.btn-ask {
	margin: 0;
	height: 90px;
	margin-top: 10px;
}

.pg-skin-video ion-item img.clinic,
.pg-video-detail ion-item img.clinic {
	width: 154px;
	height: 86px;
	object-fit: cover;
	border-radius: 8px;
}
.pg-skin-video .order {
	--min-height: auto;
	padding-top: 5px;
}
.pg-skin-video .order ion-label {
	font-size: 14px;
	margin: 0;
}
.pg-skin-video .order .icon {
	margin: 0;
}
.pg-skin-video ion-list > ion-item:first-of-type {
	padding-top: 0;
}
.pg-skin-video h5 {
	font-size: 14px;
	color: #282828;
}

.pg-skin-video h4 {
	font-size: 16px;
	font-weight: 500;
}

.pg-skin-video .btn-ask,
.pg-video-detail .btn-ask,
.pg-skin-info .btn-ask {
	background-color: #7bd2de;
	color: #282828;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.pg-skin-info .btn-ask,
.pg-skin-video .btn-ask {
	padding-top: 5px;
}
.pg-video-detail .btn-ask {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 20px 0;
	padding-top: 18px;
	padding-bottom: 17px;
	border-radius: 12px;
}

.pg-skin-info .btn-ask img,
.pg-skin-video .btn-ask img {
	height: 100px;
}

.pg-video-detail .btn-ask {
	position: relative;
}

.pg-video-detail .btn-ask img {
	position: absolute;
	right: 20px;
	bottom: 0;
	height: 78px;
}

.pg-video-detail .btn-ask::after {
	content: "";
	display: inline-block;
	width: 24px;
	height: 24px;
	position: absolute;
	right: 8px;
	background: url(../img/icons/icon-arrow-next_bg.svg) center center no-repeat;
}

.pg-skin-info .btn-ask p,
.pg-skin-video .btn-ask p,
.pg-video-detail .btn-ask p {
	color: #282828;
	margin: 0;
}

.pg-skin-info .btn-ask p ion-icon,
.pg-skin-video .btn-ask p ion-icon,
.pg-video-detail .btn-ask p ion-icon {
	width: 24px;
}

.pg-skin-info .btn-ask p:nth-child(1),
.pg-skin-video .btn-ask p:nth-child(1) {
	font-size: 14px;
	font-weight: 500;
}

.pg-skin-info .btn-ask p:nth-child(2),
.pg-skin-video .btn-ask p:nth-child(2) {
	font-size: 20px;
	font-weight: 600;
}

.pg-skin-info .btn-ask p:nth-child(3),
.pg-skin-video .btn-ask p:nth-child(3) {
	display: flex;
	align-items: center;
	font-size: 12px;
	color: #fff;
	font-weight: 600;
	margin-top: 4px;
}

.pg-video-detail .btn-ask p:nth-child(1) {
	font-size: 10px;
	font-weight: 500;
}

.pg-video-detail .btn-ask p:nth-child(2) {
	font-size: 14px;
	font-weight: 600;
}

.pg-video-detail .profile {
	margin: 10px 0 20px 0;
}

.pg-video-detail .profile h3 {
	margin: 0 0 5px 0;
}

.pg-video-detail ion-grid ion-row:first-of-type h4.hashtag {
	font-size: 14px;
	font-weight: 500;
	margin-top: 5px;
}

.pg-video-detail .description {
	display: block;
}
.pg-video-detail .description p {
	margin: 0;
	word-break: break-word;
	white-space: break-spaces;
}

.pg-video-detail .padding-bottom-0 {
	padding-bottom: 0;
}

.pg-video-detail .padding-0 {
	padding: 0;
}

.pg-video-detail video {
	width: 100%;
	background-color: #e0e0e0;
}

.pg-video-detail .video-item {
	padding: 0;
}

.app .pg-video-detail .video-thumbnail-wrapper {
	position: relative;
	height: 0;
	padding-bottom: 56.25%;
}

.app .pg-video-detail .video-thumbnail {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.pg-video-detail h2 {
	color: #282828;
	font-weight: 700;
	font-size: 20px;
}

.pg-video-detail h3 {
	color: #282828;
	font-weight: 700;
	font-size: 16px;
}

.pg-video-detail h4 {
	color: #282828;
	font-weight: 600;
	font-size: 18px;
}

.pg-video-detail p {
	color: #282828;
	font-weight: 500;
	font-size: 14px;
}

.pg-video-detail .item-label ion-label {
	white-space: break-spaces;
}
.pg-video-detail .item-label ion-label h2 {
	width: calc(100% - 15px);
}

.pg-video-detail ion-avatar,
.pg-video-detail ion-avatar > img {
	border-radius: 100%;
	height: 60px;
	width: 60px;
}

.pg-video-detail .txt-in-btn {
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
}

.pg-video-detail .impt {
	font-weight: 600;
	font-size: 16px;
}

.pg-video-detail ion-grid ion-row:first-of-type h4 {
	font-size: 16px;
}

.pg-video-detail ion-grid ion-row:first-of-type p {
	color: #c6c6c6;
	margin: 0;
}

.pg-video-detail ion-grid ion-row:first-of-type ion-item {
	margin-top: 6px;
	margin-bottom: 6px;
}
.pg-video-detail ion-grid ion-row:first-of-type .bookmark {
	position: absolute;
	top: 10px;
	right: 0;
}

.pg-video-detail .ad-area {
	margin-top: 11px;
}

.pg-video-detail .related-video ion-label {
	h4 {
		font-size: 14px;
	}
	h5 {
		font-size: 13px;
	}
}

// media query
@media screen and (min-width: 768px) {
	.pg-skin-video .menu,
	.pg-skin-info .menu {
		padding-left: 54px;
		padding-right: 10px;
	}

	.app .pg-video-detail .video-thumbnail-wrapper {
		width: 100%;
		/* max-width: 480px; */
		height: 269.53px;
		max-height: 269.53px;
	}

	.app .pg-video-detail .video-thumbnail {
		object-fit: cover;
		height: 100%;
		max-height: 269.53px;
	}
}

/*
 * File        : skin.scss
 * Author      : AI2U (KHS)
 * Description : skin info page common css
*/

.pg-skin-info {
	.info-contents {
		display: flex;
		flex-wrap: wrap;
		padding: 20px 20px 0 20px;
		justify-content: space-between;
	}

	.info-item {
		position: relative;
		width: calc(100% / 2 - 8px);
		margin-bottom: 15px;
	}
	.info-item ion-item {
		display: flex;
		--background: transparent;
	}
	.info-item .img img {
		border-radius: 8px;
		cursor: pointer;
	}

	.info-item ion-card-content {
		width: 100%;
		height: 100%;
		padding: 0;
	}
	.info-item ion-card-content img {
		width: 100%;
		height: 100%;
	}

	.bookmark-area {
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.label-icon::after {
		content: "";
		position: absolute;
		display: inline-block;
		width: 32px;
		height: 32px;
		background-color: #000;
		border-radius: 50%;
		opacity: 0.38;
	}
}

.pg-skin-info-detail {
	.detail-item {
		width: 100%;
		margin-bottom: 11px;

		&:last-child {
			padding-bottom: 30px;
			margin-bottom: 0;
		}
	}
	.detail-item img {
		width: 100%;
		height: 100%;
	}

	.bookmark-area.ad {
		margin-top: 4px;
	}
}

.bookmark-area {
	display: flex;
	align-items: center;
	justify-content: center;
}

.list-bookmark-active {
	background: url(../img/icons/icon-bookmark-active_white.svg) center center no-repeat;
}

.list-bookmark {
	background: url(../img/icons/icon-bookmark_white.svg) center center no-repeat;
}

.info-bookmark-active {
	background: url(../img/icons/icon-bookmark_active.svg) center center no-repeat;
}

.info-bookmark {
	background: url(../img/icons/icon-bookmark_black.svg) center center no-repeat;
}

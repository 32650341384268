/*
 * File        : hospital.scss
 * Author      : AI2U (KHS)
 * Description : hospital page common css
*/
ion-modal.my-clinic-modal {
	/* position: absolute; */
	margin-bottom: 70.7px;
	/* border-bottom: solid 1px #e0e0e0; */
	/* display: block; */
	pointer-events: none !important;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	--box-shadow: 0px 15px 25px 0 #0000001a;
}

ion-modal.my-clinic-modal #ion-react-wrapper {
	pointer-events: auto;
	max-width: 480px;
	margin: auto;
}

ion-modal.my-clinic-modal::part(backdrop) {
	pointer-events: none !important;
	background-color: transparent;
}

ion-modal.my-clinic-modal ion-toolbar {
	--background: #fff;
}

.pg-find-clinic .rescan {
	position: absolute;
	top: 120px;
	overflow: hidden;
	z-index: 1;
	width: 135px;
	left: 50%;
	margin-left: -65px;
	text-align: left;
	border-radius: 50px;
	--min-height: 35px;
	box-shadow: 0px 0px 10px 0 #00000033;
}
.pg-find-clinic .current-location:active,
.pg-find-clinic .current-location:focus {
	background: #e0e0e0;
}
.pg-find-clinic .rescan:active,
.pg-find-clinic .rescan:focus {
	--background: #e0e0e0;
}

.pg-find-clinic .rescan ion-icon {
	margin-left: 10px;
	margin-right: 10px;
	height: 20px;
	width: 20px;
	font-weight: 600;
}

.pg-find-clinic .rescan .item.sc-ion-label-ios-h,
.pg-find-clinic .item .sc-ion-label-ios-h {
	font-size: 14px;
	margin: 0;
}

.pg-find-clinic .current-location {
	position: absolute;
	top: 120px;
	right: 20px;
	overflow: hidden;
	background-color: #fff;
	z-index: 1;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pg-find-clinic .current-location .icon {
	height: 26px;
	width: 26px;
}

.pg-find-clinic .custom_typecontrol {
	overflow: hidden;
	width: 100%;
	margin: auto;
	padding: 0;
	z-index: 1;
	border: solid 1px #e0e0e0;
	box-shadow: 0 5px 20px 0 #00000029;
	height: auto;
	bottom: 100px;
}

.pg-find-clinic .wrap {
	background-color: transparent;
}

.pg-find-clinic .map_wrap {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: calc(100% - 104px) !important;
}
.pg-find-clinic .radius_border {
	border: 1px solid #919191;
	border-radius: 5px;
}

.pg-find-clinic .custom_typecontrol .item.sc-ion-label-ios-h,
.pg-find-clinic .custom_typecontrol .sc-ion-label-ios-h {
	margin-top: 20px;
	margin-left: 20px;
	margin-bottom: 20px;
}

.pg-find-clinic .custom_typecontrol img.icon {
	margin-right: 20px;
}
/* 
:host(.modal-sheet) {
  --height: 330px;
} */

ion-modal.pg-filter {
	height: 100%;
	--height: 100%;
	max-height: 100%;
}

/* ion-modal.filter::part(backdrop), */
ion-modal.filter::part(content) {
	background-color: transparent;
}

ion-modal.filter {
	max-height: 100%;
	height: 100%;
	align-items: flex-end;
	top: auto;
	--box-shadow: 0px 15px 25px 0 #0000001a;
	--border-radius: 0;
}

ion-modal.filter #ion-react-wrapper {
	max-width: 480px;
	margin: auto;
	justify-content: flex-end;
}

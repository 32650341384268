.dimmed {
	position: fixed;
	top: 0;
	left: 50%;
	z-index: 100;
	transform: translate(-50%, 0);
	background: #000;
	width: 480px;
	height: 100%;
	opacity: 1;
}

// 광고 progress
.progress-modal {
	position: absolute;
	width: 480px;
	margin: 0 auto;
	background: #fff;
	order: 1;
	z-index: 100;
	height: 100%;
	--height: 100%;
	inset: 0px;
	--border-radius: 0;
	cursor: initial;

	&::part(backdrop) {
		pointer-events: none;
	}

	.ad-contents-wrap {
		position: relative;
		height: 100%;
		.progress-inner {
			transform: translate(-50%, 0);
		}

		.ad-progress {
			.title-area {
				max-height: 63px;
				.title.ad-title {
					max-width: 192px;
				}

				.sub-title {
					margin: 5px 0 0 0;
					color: #282828;
				}
			}

			.info-area {
				.info {
					color: #282828;
				}
			}

			.info-area.ad-info {
				max-width: 320px;
			}

			.progress {
				position: relative;
				margin: 20px 0 31px 0;

				.CircularProgressbar .CircularProgressbar-trail {
					stroke-width: 6px;
				}

				.CircularProgressbar .CircularProgressbar-path {
					stroke-width: 6px;
				}

				.icon-complete {
					display: inline-block;
					background: url(../../../img/icons/icon-check-blue.svg) center center no-repeat;
				}
			}
		}
	}
	.ad-contents-inner {
		position: relative;
		min-height: 744px;
	}
	.ad-contents {
		position: absolute;
		width: 100%;
		top: 132px;
		left: 50%;
		transform: translate(-50%, 0);
		padding: 0;
	}

	.ad {
		position: absolute;
		top: 20px;
		right: 20px;
		z-index: 10;
	}
}

.progress-wrap {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;

	.progress-inner {
		width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.title-area {
		.title {
			margin: 0 auto;
			font-size: 20px;
			font-weight: bold;
			line-height: 1.6;
			letter-spacing: -0.5px;
			color: #44bbcd;
		}
		.sub-title {
			font-size: 16px;
			font-weight: 500;
			line-height: 2;
			letter-spacing: -0.4px;
			color: #fff;
		}
	}

	.info-area {
		max-width: 269px;
		margin: 0 auto;

		.info {
			font-size: 14px;
			line-height: 1.57;
			letter-spacing: -0.35px;
			color: #fff;
		}
	}
}

.progress {
	margin: 56px 0 66px 0;

	.CircularProgressbar {
		width: 60px;
		height: 60px;
		overflow: visible;
	}
	.CircularProgressbar .CircularProgressbar-path {
		stroke: #44bbcd;
		stroke-width: 14;
	}
	.CircularProgressbar .CircularProgressbar-text {
		fill: #44bbcd;
		font-weight: 600;
		line-height: 1;
	}
	.CircularProgressbar .CircularProgressbar-trail {
		stroke-width: 14;
	}
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
	.progress-modal {
		margin-inline: var(--pc-padding-horizontal);
	}

	.progress-modal .ad-contents-inner {
		min-height: 50%;
		max-height: 50%;
		overflow: hidden;
	}
	.progress-modal .ad-contents {
		top: 0;
	}
	.progress-modal .ad-contents-wrap .progress-inner {
		margin-top: 24px;
	}
}

@media screen and (max-width: 767px) {
	.progress-modal {
		overflow-y: scroll;
		width: 100%;
		--width: 100%;
		margin: 0 auto;
	}
}

/*
 * File        : global.scss
 * Author      : AI2U (KHS)
 * Description : global css
*/

h2 {
	font-size: 18px;
	font-weight: 600;
	margin-top: 0px;
	margin-bottom: 10px;
}

h4 {
	font-size: 12px;
}

ion-select {
	font-size: 14px;
}

.footer {
	background-color: #282828;
	color: #b5b5b5;
}

.title {
	font-size: 18px;
	font-weight: bold;
	text-align: center;
}

.info {
	line-height: 1.8rem;
}

.no-result {
	text-align: center;
	padding-top: 60px;
	font-size: 14px;
	color: #777;
	font-weight: 400;
}

.action-sheet-button-inner {
	color: #282828;
}

.ion-page ion-select.error,
.ion-page ion-input.error {
	border-bottom: solid 2px #44bbcd;
}

.sc-ion-input-ios-h,
ion-select {
	opacity: 1;
	--placeholder-opacity: 1;
	--placeholder-color: #e0e0e0;
}

.common-tab-bar {
	height: var(--tabbar-height);
	--background: #fff;
	border-color: #e0e0e0;
}

.common-tab-bar ion-icon {
	width: 25px;
	height: 25px;
}

.common-tab-bar ion-tab-button {
	--ripple-color: #44bbcd (--color-selected);
	--color-selected: #44bbcd;
	--color: #646464;
}

.common-tab-bar ion-tab-button[tab="etc"] {
	--color: #ff7473;
}

.common-tab-bar ion-tab-button[tab="etc"] ion-icon {
	width: 50px;
	height: 50px;
}

.common-tab-bar ion-tab-button[aria-selected="true"] ion-label {
	--color: #44bbcd;
	color: #44bbcd;
}

.common-tab-bar ion-tab-button ion-label {
	margin-top: 2px;
	font-size: 12px;
}

.common-tab-bar ion-tab-button.tab-selected ion-label {
	--ripple-color: #44bbcd (--color-selected);
	--color-selected: #44bbcd;
	--color: #44bbcd;
}

.list-md .item-lines-none {
	border-bottom: solid 1px #dedede;
}

.hashtag span {
	cursor: pointer;
}

// category menu
.menu {
	height: 49px;
	padding-left: 20px;
	padding-right: 70px;
	border-bottom: solid 1px #e0e0e0;
}

.menu ion-segment-button {
	--indicator-height: 3px;
}
.menu ion-segment-button::part(indicator) {
	opacity: 0;
}
.menu ion-segment-button::part(indicator-background) {
	background: transparent;
}

.menu ion-segment-button::part(native) {
	color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.6);
}

.menu ion-segment-button.selected::part(indicator-background),
.menu ion-segment-button.active::part(indicator-background) {
	background: var(--indicator-color);
}

.menu ion-segment-button[aria-selected="false"]::part(indicator-background) {
	background: transparent;
}

.menu ion-segment-button.selected::part(indicator),
.menu ion-segment-button.active::part(indicator) {
	opacity: 1;
}
.menu ion-segment-button.selected::part(native),
.menu ion-segment-button.active::part(native) {
	color: #44bbcd;
}
.menu ion-segment-button[aria-selected="false"]::part(native) {
	color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.6);
}

.menu-wrap .menu-contents {
	position: absolute;
	padding: 10px 15px;
	box-shadow: 0 5px 20px 0 #00000029;
}

.wrap {
	border-bottom-left-radius: 16px;
	border-bottom: 0;
	border-bottom-right-radius: 16px;
	background-color: #fff;

	width: 100%;
	z-index: 2;
	left: 0;
	right: 0;
}

.wrap ion-chip {
	margin: 5px;
	padding: 7px 16px 9px;
	border-color: #c6c6c6;
	border-radius: 20px;
}

.wrap ion-chip.selected {
	border-color: #44bbcd;
	background-color: #44bbcd;
}

.wrap ion-chip ion-label.ion-color.sc-ion-label-md-h {
	color: #c6c6c6;
}

.wrap ion-chip.selected ion-label.ion-color.sc-ion-label-md-h {
	color: #fff;
}

.menu-wrap {
	position: relative;
	z-index: 1;
}
.menu-wrap ion-segment {
	--background: #fff;
}

.menu-wrap img.icon {
	cursor: pointer;
	margin-top: 12px;
}

.menu-all {
	position: absolute;
	top: 0;
	right: 0;
	background-color: #fff;
	line-height: 48px;
	height: 48px;
	padding-right: 20px;
	padding-left: 30px;
	z-index: 2;
}

.menu-before,
.menu-next {
	display: none;
	z-index: 2;
}

.segment ion-segment,
.segment ion-segment ion-segment-button {
	height: 48px;
	min-height: 48px;
}

.segment ion-segment-button {
	--indicator-height: 3px;
	border-bottom: solid 1px #e0e0e0;
	background-color: #fff;
}

// icon
.icon {
	width: 24px;
	height: 24px;
}

.icon.inline {
	display: inline-block;
	vertical-align: middle;
}

.icon-space {
	height: 25px;
	width: 25px;
	margin-bottom: 5px;
	margin-top: 5px;
}

.wrap-etc-icon {
	background-color: #44bbcd;
	width: 40px;
	height: 40px;
	padding: 8px;
	border-radius: 100%;
	position: absolute;
	right: 20px;
}

// checkbox
.check ion-checkbox {
	margin-right: 10px;
	height: 18px;
	width: 18px;
	vertical-align: middle;
}

// tab
.tab-etc {
	padding-top: 16px;
	padding-bottom: 16px;
}
.tab-etc ion-item {
	font-size: 16px;
	font-weight: 500;
	color: #282828;
	width: 100%;
	height: 56px;
	--min-height: 56px;
}

.tab-etc ion-item img {
	margin-left: 20px;
	margin-right: 20px;
}

.tab-home .icon-space {
	background-image: url("../../img/tab_home.png");
	background-size: 24px;
	background-repeat: no-repeat;
	background-position-x: center;
}

.tab-home:hover .icon-space,
.tab-home.tab-selected .icon-space {
	background-image: url("../../img/tab_home_hover.png");
}

.tab-skin-analysis .icon-space {
	background-image: url("../../img/tab_analysis.png");
	background-size: 24px;
	background-repeat: no-repeat;
	background-position-x: center;
}

.tab-skin-analysis:hover .icon-space,
.tab-skin-analysis.tab-selected .icon-space {
	background-image: url("../../img/tab_analysis_hover.png");
}

.tab-skin-change .icon-space {
	background-image: url("../../img/tab_dermal_change.png");
	background-size: 100%;
	background-repeat: no-repeat;
	background-position-x: center;
}

.tab-skin-change:hover .icon-space,
.tab-skin-change.tab-selected .icon-space {
	background-image: url("../../img/tab_dermal_change_hover.png");
}

.tab-skin-log .icon-space {
	background-image: url("../../img/tab_history.png");
	background-size: 22px;
	background-repeat: no-repeat;
	background-position-x: center;
}

.tab-skin-log:hover .icon-space,
.tab-skin-log.tab-selected .icon-space {
	background-image: url("../../img/tab_history_hover.png");
}

.tab-skin-hospital .icon-space {
	background-image: url("../../img/tab_hospital.svg");
	background-size: 24px;
	background-repeat: no-repeat;
	background-position-x: center;
}

.tab-skin-hospital:hover .icon-space,
.tab-skin-hospital.tab-selected .icon-space {
	background-image: url("../../img/tab_hospital_hover.svg");
}

.etc-icon {
	background-image: url("../../img/icons_add.png");
	background-size: 24px;
	background-repeat: no-repeat;
}

.etc-icon-selected {
	background-image: url("../../img/icons_close.png");
	background-size: 24px;
	background-repeat: no-repeat;
}

// 검색
ion-searchbar.sc-ion-searchbar-md-h,
ion-searchbar.sc-ion-searchbar-ios-h {
	--box-shadow: none;
	border: solid 2px #44bbcd;
	border-radius: 8px;
	padding: 0;
	height: 44px;
	margin-bottom: 5px;
	text-align: left;
}

.searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
	font-size: 16px;
	-webkit-padding-start: 16px;
	padding-inline-start: 16px;
	-webkit-padding-end: 30px;
	padding-inline-end: 30px;
	padding-bottom: 2px;
}

.searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-search-icon.sc-ion-searchbar-ios {
	-webkit-margin-start: 10px;
	margin-inline-start: 10px;
}

.search.icon {
	position: absolute;
	right: 8px;
}
.searchbar-input.sc-ion-searchbar-ios {
	background-color: transparent;
}

.searchbar-search-icon {
	display: none !important;
}

.searchbar-clear-button.sc-ion-searchbar-ios {
	margin-right: 45px;
}

.searchbar-clear-button ion-icon {
	padding: 0;
}

// input
.error-message {
	color: #44bbcd;
	font-size: 12px;
	margin-top: 6px;
	text-align: left;
}
.success-message {
	color: #40e593;
	font-size: 12px;
	margin-top: 6px;
	text-align: left;
}

// alert
.sc-ion-alert-ios-h {
	--width: 280px;
	--max-width: 280px;
}
.sc-ion-alert-ios-h ion-backdrop {
	pointer-events: none !important;
}
.alert-wrapper.sc-ion-alert-ios {
	border-radius: 16px;
}
.alert-button-group.sc-ion-alert-ios {
	height: 53px;
}
.alert-button.sc-ion-alert-ios {
	font-size: 16px;
	font-weight: 500;
	height: 100%;
}
.primary.alert-button.sc-ion-alert-ios .alert-button-inner {
	color: #44bbcd;
}

.alert-css1 {
	--height: 128px;
	font-size: 16px;
	font-weight: 500;
}
.alert-css1 .alert-message {
	margin-bottom: 27px;
	height: 24px;
	padding-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
}

.alert-css1 .alert-head {
	height: 24px;
}

.alert-css2 {
	font-size: 16px;
	font-weight: 500;
}

.alert-css2 .alert-message {
	margin-bottom: 29px;
	/* height: 48px; */
	padding-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
}

.alert-css2 .alert-head {
	height: 30px;
}

.alert-button .alert-button-inner {
	font-size: 16px;
	font-weight: 500;
	color: #44bbcd;
}

.alert-button:first-child .alert-button-inner {
	color: #ff7473;
}

// 액션시트
.action-sheet-button.sc-ion-action-sheet-ios {
	font-size: 16px;
	background-color: #fff;
}
.action-sheet-selected.sc-ion-action-sheet-ios span {
	color: #44bbcd;
}

// button
.btn-wrap {
	padding: 24px 20px;
	background: #fff;
	ion-button {
		border-radius: 26px;
	}
}

.btn-bottom .button {
	width: 100%;
	height: 52px;
	border-radius: 26px;
	background-color: #44bbcd;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.38;
	letter-spacing: -0.4px;
	color: #fff;
}

.btn-bottom__single .button {
	width: 100%;
}

.btn-bottom__double button {
	height: 52px;
	border-radius: 26px;
	font-size: 16px;
	line-height: 1.38;
	letter-spacing: -0.4px;
	font-weight: 500;
}
.btn-bottom__double ion-row {
	flex-flow: row;
}
.btn-bottom__double .btn-restart {
	width: calc(100% / 1.5);
	border: 1px solid #e0e0e0;
	margin-right: 16px;
}
.btn-bottom__double .btn-confirm {
	width: 100%;
	color: #fff;
	background: #44bbcd;
}

.bottom-contents .btn-bottom .button {
	position: relative;
}
.bottom-contents .btn-bottom .button::after {
	content: "";
	position: absolute;
	right: 14px;
	display: inline-block;
	width: 24px;
	height: 24px;
	background-image: url(../../img/icons/icon-next.svg);
}

.btn-more {
	width: 100%;
	--padding-start: 0;
	--inner-padding-end: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	cursor: pointer;
}

.btn-more p {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: 0;
	text-align: center;
	color: #777;
}

.btn-more em {
	display: inline-block;
	width: 24px;
	height: 24px;
	background-image: url("../../img/icons_arrow_down.png");
	background-size: 100%;
}

.btn-more:hover p {
	color: #282828;
}

// popover
ion-popover::part(content) {
	border: 0;
	overflow: visible;
}

.popover-info {
	&__wrap {
		--width: calc(var(--popover-width));
		--offset-y: calc(50vh - var(--tabbar-height) - var(--popover-height) / 2.3);
		--background: transparent;
	}

	&__contents {
		&::after {
			border-top: 10px solid #44bbcd;
			border-left: 9px solid transparent;
			border-right: 9px solid transparent;
			border-bottom: 0px solid transparent;
			content: "";
			position: absolute;
			top: 55px;
			left: 50%;
			transform: translate(-50%, 0);
		}

		ion-list {
			border-radius: 8px;
		}

		.item {
			--background: var(--ion-background-color, #44bbcd);
			border-radius: 8px;

			ion-label {
				margin: 8px 13px 11px 13px;
				font-size: 12px;
				text-overflow: initial;
				text-align: center;
				--color: #fff;
				white-space: inherit;
			}
		}

		span {
			font-size: 12px;
			font-weight: 500;
			line-height: 1.33;
			letter-spacing: -0.3px;
			color: #fff;
		}
	}
}

// checkbox
.checkbox-container {
	display: flex;
	.checkbox {
		display: flex;
		align-items: center;
		flex-direction: column;
		margin: 0 14px;

		ion-label {
			font-size: 10px;
			line-height: 2.2;
			letter-spacing: -0.25px;
		}

		ion-checkbox {
			width: 16px;
			height: 16px;

			&:last-child {
				margin-right: 0;
			}
		}

		ion-checkbox.checkbox-checked::part(container) {
			border-color: #acacac;
			background: #acacac;
		}
		ion-checkbox::part(container) {
			border-width: 2px;
		}

		&.confirm ion-checkbox::part(container) {
			border-color: transparent;
		}
	}
	.active ion-checkbox::part(container) {
		border-color: #282828;
	}
}

// floating menu
.floating-menu {
	position: fixed;
	bottom: 24px;
	left: 0;
	.btn-wrap {
		padding: 0 20px;
		background: transparent;
		justify-content: space-between;
		display: flex;

		.btn {
			width: 52px;
			height: 52px;
			box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
			--border-radius: 26px;
			--background-activated: transparent;
			--background-focused: transparent;

			i {
				width: 24px;
				height: 24px;
				background-position: center center;
				background-repeat: no-repeat;
			}
		}

		.btn-prev {
			--background: #fff;
			--background-activated: #44bbcd;
			--background-focused: #44bbcd;

			.icon-prev {
				background-image: url(../../img/icons/icon-back.svg);
			}
		}
		.btn-prev.ion-activated .icon-prev {
			background-image: url(../../img/icons/icon-prev.svg);
		}

		.btn-next {
			--background: #fff;

			.icon-next {
				background-image: url(../../img/icons/icon-next-black.svg);
			}
		}
		.btn-next-active {
			--background: #44bbcd;
			.icon-next {
				background-image: url(../../img/icons/icon-next.svg);
			}
		}

		.btn-complete {
			width: 96px;
			background: #fff;
			--background: #fff;
			box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
			color: #282828;
		}
		.btn-complete.selected {
			background: #44bbcd;
			--background: #44bbcd;
			color: #fff;
		}

		ion-button::part(native) {
			--background-hover: transparent;
		}
	}
	.btn-wrap.align-right {
		justify-content: flex-end;
	}
}

// slick
.slick-slide img {
	width: 100%;
}
.slick-dots {
	position: initial;
}
.slick-dots .slick-active .pagination img {
	border: 2px solid #44bbcd;
}

.arrow {
	top: -25px;
}
.slick-prev {
	left: 20px;
}
.slick-next {
	right: 20px;
}
.slick-arrow::before {
	content: "";
	width: 24px;
	height: 24px;
	display: inline-block;
	opacity: 1;
}
.slick-prev::before {
	background: url(../../img/icons/icon-arrow-prev.svg) center center no-repeat;
}
.slick-next::before {
	background: url(../../img/icons/icon-arrow-next.svg) center center no-repeat;
}
.slick-prev::before,
.slick-next::before {
	color: transparent;
}
.slick-prev.slick-disabled::before,
.slick-next.slick-disabled::before {
	opacity: 0.13;
}

// slider text
.img-area {
	position: relative;
}

.img-area p {
	bottom: 10px;
	color: #fff;
	font-size: 12px;
	letter-spacing: -0.3px;
	margin: 0;
	position: absolute;
	right: 20px;
	text-align: right;
}

// bookmark
.bookmark {
	width: 24px;
	height: 24px;
	z-index: 1;
	cursor: pointer;
}

.bookmark-contents {
	position: fixed;
	top: 17px;
	right: 20px;
	padding-inline: var(--pc-padding-horizontal);
	z-index: 10;
}

.bookmark.ad {
	width: 28px;
}

.icon-ad {
	width: 28px;
	height: 16px;
	background: url(../../img/icons/icon-info-ad.svg) no-repeat;
}

// 광고 버튼
.ad-area {
	padding: 9px 0 22px 0;

	.btn-ad {
		position: relative;
		width: 100%;
		padding: 16px 0;
		border: 1px solid #e0e0e0;
		border-radius: 8px;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: -0.35px;

		&::after {
			content: "";
			display: inline-block;
			width: 24px;
			height: 24px;
			position: absolute;
			top: 10px;
			right: 10px;
			background-image: url(../../img/icons/icon-arrow-next.svg);
		}
	}
}

// tab bar
@media screen and (max-width: 768px) {
	.popover {
		--width: var(--popover-width);
		--height: var(--popover-height);
		--offset-x: calc(50vw - var(--mobile-padding-horizontal) - var(--popover-width) / 2) - 20px;
		--offset-y: calc(50vh - var(--tabbar-height) - var(--popover-height) / 2);
	}
}

@media screen and (min-width: 769px) {
	.popover {
		--width: calc(var(--popover-width) + 40px);
		--height: var(--popover-height);
		--offset-x: calc(50vw - var(--pc-padding-horizontal) - var(--popover-width) / 2) - 48px;
		--offset-y: calc(50vh - var(--tabbar-height) - var(--popover-height) / 2);
	}
}

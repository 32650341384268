/*
 * File        : base.scss
 * Author      : AI2U (KHS)
 * Description : base css
*/

@font-face {
	font-family: "Noto Sans KR";
	font-style: normal;
	font-weight: 100;
	src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff2) format("woff2"),
		url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.woff) format("woff"),
		url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Thin.otf) format("opentype");
}
@font-face {
	font-family: "Noto Sans KR";
	font-style: normal;
	font-weight: 300;
	src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff2) format("woff2"),
		url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.woff) format("woff"),
		url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Light.otf) format("opentype");
}
@font-face {
	font-family: "Noto Sans KR";
	font-style: normal;
	font-weight: 400;
	src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2) format("woff2"),
		url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff) format("woff"),
		url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf) format("opentype");
}
@font-face {
	font-family: "Noto Sans KR";
	font-style: normal;
	font-weight: 500;
	src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2) format("woff2"),
		url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff) format("woff"),
		url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf) format("opentype");
}
@font-face {
	font-family: "Noto Sans KR";
	font-style: normal;
	font-weight: 700;
	src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2) format("woff2"),
		url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff) format("woff"),
		url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf) format("opentype");
}
@font-face {
	font-family: "Noto Sans KR";
	font-style: normal;
	font-weight: 900;
	src: url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff2) format("woff2"),
		url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.woff) format("woff"),
		url(//fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Black.otf) format("opentype");
}

*,
body,
h1,
h2,
h3,
h4,
h5,
h6,
input,
textarea,
select {
	font-family: "Noto Sans KR", sans-serif !important;
	letter-spacing: -0.25px;
}

html {
	font-size: 100%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
}

body {
	color: #282828;
	overflow-x: hidden;
}

p,
span,
a,
h1,
h2,
h3,
h4,
h5,
h6,
b,
dt,
dd,
li,
label,
button,
abbr {
	color: #282828;
}

p span,
a span,
h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span,
b span,
dt span,
dd span,
li span,
label span,
button span,
abbr span,
strong span {
	color: inherit;
}

hr {
	border: 0px;
	border-top: 1px solid #ccc;
}

// IR
legend,
.hidden {
	position: absolute;
	clip: rect(0, 0, 0, 0);
	width: 1px;
	height: 1px;
	margin: -1px;
	overflow: hidden;
}

caption {
	position: relative;
	clip: rect(0 0 0 0);
	width: 1px;
	height: 1px;
	margin: -1px;
	overflow: hidden;
}

a {
	color: #282828;
	text-decoration: none;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.icon,
i[class^="icon-"] {
	display: inline-block;
	span {
		position: absolute;
		clip: rect(0, 0, 0, 0);
		width: 1px;
		height: 1px;
		margin: -1px;
		overflow: hidden;
	}
}

button,
.button {
	border: 0;
	border-color: transparent;
	background-color: transparent;
	cursor: pointer;
	padding: 0;
	&:focus {
		outline: 0;
	}
}

table {
	max-width: 100%;
}

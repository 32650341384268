.simulation-page {
	.simulation-contents {
		&-img {
			height: 704px;
			img {
				height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}

		&-upload {
			padding: 24px 0 0 0;
			margin: 0 auto;
			text-align: center;

			h2 {
				font-size: 20px;
			}

			h3 {
				font-size: 16px;
				font-weight: normal;
				margin: 0 0 6px 0;
			}
		}
	}
}

.simulation-detail-page {
	&-contents {
		height: 100%;
		background-color: #fff;

		.item {
			position: relative;

			p {
				position: absolute;
				top: 0;
				width: 100%;
				margin: 0;
				padding: 5px 0 7px 0;
				background-color: #000000a8;
				text-align: center;

				span {
					font-size: 14px;
					font-weight: 500;
					color: #fff;
				}
			}

			&:last-child {
				margin-left: 4px;
			}

			.download-area {
				display: flex;
				justify-content: center;
				margin-top: -5px;
				border: 1px solid #e0e0e0;
				background-color: #fff;

				.btn-download {
					display: flex;
					align-items: center;
					padding: 10px 0 13px 0;
					width: 100%;
					justify-content: center;
					cursor: pointer;
					font-size: 16px;
					font-weight: 500;
					letter-spacing: -0.4px;
				}

				.icon-download {
					width: 24px;
					height: 24px;
					margin-right: 4px;
					background: url(../img/icons/icon-download.svg) no-repeat;
				}
			}
		}
		.flex {
			float: left;
			width: calc(100% / 2 - 2px);
			margin-top: -1px;
			padding-bottom: 20px;
			background-color: #fff;
		}
	}
}

@media screen and (max-width: 767px) {
	.simulation-page .simulation-contents-img {
		height: auto;
	}
}

// modal
.modal {
	--width: 320px;

	ion-toolbar {
		height: 64px;
		--min-height: 64px;
	}

	.swiper-slide .ios {
		margin: 0;
		box-shadow: none;
		border-radius: 0;
	}

	.swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 0 3px;
		background: #e0e0e0;
		opacity: 1;
	}
	.swiper-pagination-bullets .swiper-pagination-bullet-active {
		width: 24px;
		border-radius: 4px;
		background: #44bbcd;
	}

	.btn-close {
		position: absolute;
		width: 24px;
		height: 24px;
		right: 20px;
		top: 20px;
		z-index: 10;
	}

	.modal-img {
		height: 159px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.modal-info {
		margin: 85px 0 12px 0;
		font-size: 16px;
		letter-spacing: -0.4px;
	}
	.modal-info p {
		margin: 0;
	}
	.modal-close {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		.item {
			--background-hover: transparent;
		}
	}

	.modal-contents {
		padding-top: 36px;

		.info {
			text-align: center;

			h2 {
				margin: 0 81px;
				font-size: 18px;
				font-weight: bold;
				line-height: normal;
				letter-spacing: -0.45px;
			}
			p {
				margin-bottom: 24px;
				font-size: 14px;
				letter-spacing: -0.35px;
			}
		}

		.img {
			img {
				width: 100%;
				height: 240px;
			}
		}

		.btn {
			width: 100%;
			margin-top: 24px;
			display: flex;
			justify-content: center;

			ion-button {
				width: 100%;
				height: 48px;
				line-height: 1.38;
				--background: #e0e0e0;
			}

			ion-button::part(native) {
				padding: 0 50px;
				border-radius: 24px;
			}

			.checked::part(native) {
				background-color: #44bbcd;
			}
		}

		.select {
			padding: 0 20px;

			ion-radio-group {
				display: flex;
				justify-content: space-between;
			}

			ion-item.item-radio-checked::part(native) {
				border-color: #44bbcd;
			}

			ion-item.item-radio-checked ~ ion-button {
				--background: #e0e0e0;
			}

			ion-item::part(native) {
				height: 240px;
				border: 2px solid #e0e0e0;
				border-radius: 8px;
				text-align: center;
			}

			.select-item .img {
				width: 80px;
				height: 93px;
				display: inline-block;
				vertical-align: middle;
			}
			.select-item .face {
				background-image: url(../../../img/analysis-popup_face.png);
				height: 93px;
				background-size: 80px 93px;
			}
			.select-item .body {
				background-image: url(../../../img/analysis-popup_body.png);
				height: 80px;
				background-size: 80px 80px;
				margin-top: 13px;
			}

			.item-radio-checked .face {
				background-image: url(../../../img/analysis-popup_face-active.png);
			}
			.item-radio-checked .body {
				background-image: url(../../../img/analysis-popup_body-active.png);
			}

			.select-item {
				width: calc(100% / 2 - 8px);
			}
			.select-item ion-label {
				margin: 0;
				width: 100%;
				height: 100%;
				padding: 23px 0 46px 0;
				white-space: normal;
			}
			.select-item ion-label img {
				width: 80px;
			}

			.select-item ion-label .radio {
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}

			.select-item ion-radio {
				margin: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 32px;
				height: 32px;
				float: right;
				background: #e0e0e0;
				border-radius: 0 0 0 10px;
				--color-checked: transparent;
			}
			.select-item ion-radio::part(container) {
				background: url(../../../img/icons/icon-check.svg) center center;
				width: 24px;
				height: 24px;
			}

			.select-item .radio-checked {
				background-color: #44bbcd;
			}

			.select-item .text {
				margin: 0 26px;
			}
			.select-item h2 {
				margin: 16px 0 4px 0;
				font-weight: bold;
				letter-spacing: -0.4px;
				font-size: 16px;
			}
			.select-item h3 {
				font-size: 12px;
				letter-spacing: -0.3px;
			}
		}
	}
}

.modal::part(content) {
	max-height: 520px;
	border-radius: 16px;
	box-shadow: 0 8px 16px 0 #00000029;
}

.small-modal::part(content) {
	height: 444px;
}

/*
 * File        : home.scss
 * Description : home css
*/

.home h2 {
	margin-bottom: 20px;
}
.home h2:nth-of-type(2) {
	margin-top: 30px;
}

.home h2:nth-of-type(3) {
	margin-top: 15px;
}

.home .title-container {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.home ion-header ion-toolbar {
	border: transparent;
	--border-color: transparent;
}

.home ion-header ion-toolbar ion-title {
	font-size: 24px;
	font-weight: 700;
	color: #44bbcd;
	align-items: center;
	padding-top: 5px;
	padding-right: 20px;
	padding-left: 20px;
}

.home .result-grid {
	margin-bottom: 20px;
}

.home .result-grid ion-row:nth-of-type(2) ion-col {
	padding-top: 10px;
}

.home .result-grid ion-row:nth-of-type(2) ion-col:nth-of-type(1) {
	padding-right: 5px;
}

.home .result-grid ion-row:nth-of-type(2) ion-col:nth-of-type(2) {
	padding-left: 5px;
}

.home .btn-skin-result {
	height: 45px;
	--border-radius: 8px;
	--background: #e2f4f7 !important;
	--background-activated: #d6eff3 !important;
	--background-focused: #d6eff3 !important;
	--background-hover: #d6eff3 !important;
	color: #282828;
	margin: 0;
}

/** Ionic CSS Variables **/
.home .slide-banner {
	height: 260px;
	margin-bottom: 10px;
}

.home .slide-banner ion-card {
	width: 100%;
	/* max-width: 480px; */
	height: 200px;
	border-radius: 12px;
	margin-top: 0;
	cursor: pointer;
}

.home .slide-banner img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	transition: 600ms all ease-in-out;
}

.home .slide-banner .swiper-slide-active {
	transform: scale(1.05, 1.05);
}

.home .slide-banner .swiper-slide {
	transition: 400ms all ease-in-out;
	align-items: flex-start;
	margin-top: 15px;
}

.home .slide-banner .swiper-pagination-bullet {
	background: #646464;
}

.home .slide-banner .swiper-pagination-bullet-active {
	background-color: #44bbcd;
}

.home .slide-video .slide-wrap::after {
	content: "";
	display: inline-block;
	position: absolute;
	right: 4px;
	bottom: 10px;
	width: 24px;
	height: 24px;
	background-image: url(../img/icons/icon-play.svg);
}

.home .slide-video ion-slide img {
	top: 0;
	border-radius: 8px;
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.home .slide-video ion-slide .time {
	position: absolute;
	bottom: 15px;
	right: 15px;
	border-radius: 5px;
	height: 20px;
	width: 50px;
	background-color: #282828;
	color: #fff;
	font-size: 12px;
}

.home .slide-video ion-slide h4 {
	position: absolute;
	top: 85%;
	left: 50%;
	width: 100%;
	transform: translate(-50%, -50%);
	margin: 0;
}

.home .slide-video .video-container iframe,
.home .slide-video .video-container object,
.home .slide-video .video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 8px;
}

.home .slide-video .react-player__preview {
	border-radius: 8px;
}

.home .view-all {
	position: relative;
	color: #c6c6c6;
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
}

.home .slide-info ion-slide img {
	top: 0;
	border-radius: 8px;
	width: 100%;
}

.home .btn-skin-result .txt-in-btn {
	text-align: left;
	width: 100%;
	font-size: 14px;
	padding-left: 10px;
	padding-bottom: 2px;
}

.home .btn-skin-result .impt {
	color: #44bbcd;
	font-weight: 700;
}

.home .btn-skin-result img {
	width: 20px;
	height: 20px;
}

.home .btn-skin-analysis,
.home .btn-skin-change {
	font-size: 16px;
	height: 120px;
	--background: #fff;
	--background-activated: #fff !important;
	--background-focused: #fff !important;
	--background-hover: #fff !important;
	color: #282828;
	border: solid 2px #e0e0e0;
	border-radius: 8px;
	margin: 0;
	margin-bottom: 3px;
	padding: 2px;
}

.home .btn-skin-analysis:hover,
.home .btn-skin-change:hover {
	border: solid 2px #44bbcd;
}

.home .btn-skin-analysis img {
	height: 55px;
}

.home .btn-skin-change img {
	height: 55px;
}

.home .btn-skin-analysis .button-inner,
.home .btn-skin-change .button-inner {
	flex-flow: column;
}
.button-inner {
	flex-flow: column;
}
.home .grid-clinic {
	margin-top: 30px;
}
.home .grid-clinic ion-item {
	--min-height: 100px;
	padding-right: 0;
	padding-left: 0;
}

.home .grid-clinic ion-avatar,
.home .grid-clinic ion-avatar > img {
	border-radius: 8px;
	height: 80px;
	width: 80px;
}

.home .grid-clinic ion-label h4 span {
	background-color: #44bbcd;
	border-radius: 8px;
	color: #fff;
	padding-right: 5px;
	padding-left: 5px;
	font-size: 12px;
	font-weight: 500;
}

.home .grid-clinic ion-label h3 {
	color: #282828;
	font-weight: 700;
}

.home .grid-clinic ion-label p {
	color: #c6c6c6;
	font-weight: 500;
}

.home .grid-skin-dict {
	margin-bottom: 15px;
}
.home .grid-skin-dict ion-col {
	text-align: center;
	cursor: pointer;
}

.home .grid-skin-dict img {
	height: 40px;
	margin: 10px;
}

.home .grid-skin-dict h4 {
	text-align: center;
	margin-top: 5px;
}

// skin info
.home .grid-info {
	margin-bottom: 30px;
}

// banner
.home .grid-ad-banner .banner-slider {
	margin: 20px 0 0 0;
}

.home .grid-ad-banner img {
	cursor: pointer;
	width: 100%;
}

.home .grid-ad-banner .banner-slider .slick-list {
	bottom: -6px;
}

.home .footer {
	padding-top: 10px;
	padding-bottom: 15px;
}

.home .footer div {
	color: #b5b5b5;
}

.home .footer address {
	font-size: 12px;
	font-style: normal;
	font-weight: 100;
}

@media screen and (max-width: 767px) {
	.home .slide-banner {
		height: 220px;
		margin-bottom: 0;
	}

	.home .slide-banner ion-card {
		height: 160px;
	}

	.home .btn-skin-result .txt-in-btn {
		font-size: 12px;
	}
}

/*
 * File        : analysis.scss
 * Author      : AI2U (KHS)
 * Description : analysis page common css
*/

// guide
.analysis-guide {
	position: relative;
	padding: 20px 0;
	background-image: linear-gradient(to bottom, #44bbcd, #44bbcd 25%, #95e2f0);

	&::before {
		content: "";
		display: inline-block;
		background-image: url(../img/analysis-guide_img.png);
		background-size: 120px 144px;
		background-position: center center;
		width: 120px;
		height: 144px;
		position: absolute;
		right: 30px;
		top: 101px;
	}

	&__header {
		height: 36px;
		line-height: 36px;
		text-align: center;

		h2 {
			color: #fff;
		}
	}

	&__title {
		margin: 20px 0 0 0;

		h3 {
			margin: 0;
			font-size: 18px;
			line-height: 1.78;
			letter-spacing: -0.45px;
			color: #fff;
		}
		h2 {
			font-size: 24px;
			font-weight: bold;
			line-height: 1.33;
			letter-spacing: -0.6px;
			color: #fff;

			span {
				font-weight: 500;
				color: #fff;
			}
		}
		p {
			margin-top: 10px;
			font-size: 16px;
			letter-spacing: -0.4px;
		}
	}

	&__img {
		img {
			width: 100%;
			height: auto;
		}
	}

	&__category {
		margin-top: 70px;
		margin-bottom: 80px;

		.category {
			margin-bottom: 10px;
			text-align: center;

			ion-item {
				--background-hover: #ffdb63;
				--background-hover-opacity: 1;
				--background-activated: #ffdb63;
				--background-focused: #ffdb63;
			}
			ion-item:hover p {
				color: #282828;
			}

			.active {
				--background: #ffdb63;
			}
			.active p {
				color: #282828;
			}

			ion-item::part(detail-icon) {
				display: none;
			}

			ion-item::after {
				content: "";
				display: inline-block;
				position: absolute;
				right: 16px;
				top: 50%;
				transform: translate(0, -50%);
				z-index: 1;
				background: url(../img/icons/icon-arrow-next_black.svg) center center;
				width: 24px;
				height: 24px;
			}

			ion-item::part(native) {
				height: 96px;
				padding: 26px 16px 25px 30px;
				border-radius: 48px;
				box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.08);
			}

			h3 {
				margin: 0 0 4px 0;
				font-weight: bold;
				font-size: 16px;
				letter-spacing: -0.4px;
			}

			p {
				font-size: 12px;
				letter-spacing: -0.3px;
				color: #808080;
			}
		}
	}

	&__subtitle {
		position: absolute;
		bottom: 30px;
		left: 0;
		margin: 0 20px;
		span {
			font-size: 12px;
			color: #fff;
			line-height: 1.33;
			letter-spacing: -0.3px;
		}
	}
}

// result page
.analysis-result {
	&__wrap {
		.btn {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			height: 52px;
			padding: 13px 10px 15px 20px;
			border-radius: 8px;
			font-size: 16px;
			font-weight: 500;
			letter-spacing: -0.4px;
		}

		.icon-arrow {
			width: 24px;
			height: 24px;
		}
		.icon-arrow-white {
			background: url(../img/icons/icon-arrow-next_white.svg) center center no-repeat;
		}
		.icon-arrow-black {
			background: url(../img/icons/icon-arrow-next_black.svg) center center no-repeat;
		}

		.icon-guide {
			width: 16px;
			height: 16px;
			background: url(../img/icons/icon-guide.svg) center center no-repeat;
			margin-right: 4px;
		}

		.photo-area {
			display: flex;
			justify-content: space-between;
			margin-top: 16px;

			img {
				border-radius: 8px;
				min-height: 80px;
				max-height: 120px;
				height: 120px;
			}

			.photo {
				width: calc(100% / 3 - 8px);
				margin-right: 16px;
				height: auto;

				&:last-child {
					margin: 0;
				}
			}
		}
		.photo-area.only {
			justify-content: center;
			height: 100%;
		}
		.photo-area.only .photo {
			height: auto;
		}

		h3 {
			margin: 0;
			font-size: 18px;
			font-weight: bold;
			letter-spacing: -0.45px;
		}

		.title-area {
			height: 223px;
			background: #44bbcd;

			.title {
				display: flex;
				justify-content: space-between;
				padding: 24px 20px 0 20px;
				text-align: left;

				h2 {
					color: #fff;
					font-size: 24px;
					font-weight: 500;
					letter-spacing: -0.6px;
					line-height: normal;
				}
				span {
					font-weight: bold;
				}

				.name {
					color: #fff;
				}

				.btn-ar {
					height: 36px;
					padding: 9px 14px;
					background-color: #fff;
					border-radius: 18px;
					border: 1px solid #e0e0e0;
					color: #282828;
					font-size: 14px;
				}
				.btn-ar.active {
					background-color: #44bbcd;
					border: 1px solid #fff;
					color: #fff;
				}

				.highlight {
					color: #fee500;
				}
			}
		}

		.btn-wrap {
			display: flex;
			justify-content: space-between;
			padding: 0;
			margin-top: 30px;
			background-color: #ebebeb;
			.button {
				padding: 13px 0 15px 0;
				width: calc(100% / 2 - 5px);
				font-size: 15px;
			}
		}
	}

	&__change {
		.title-area {
			height: 80px;
			background: #fff;

			.title {
				h2,
				.name {
					color: #282828;
				}
				.highlight {
					color: #44bbcd;
				}
			}
		}
		.analysis-result__contents {
			padding-top: 24px;
		}

		.body-img {
			.img-area {
				height: 320px;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
		.body-img.after {
			margin-bottom: 4px;
		}
	}

	&__summary {
		position: relative;
		margin: 0 20px;

		.summary-area {
			position: absolute;
			top: -95px;
			width: 100%;
			background: #fff;
			border-radius: 16px;

			.summary {
				position: relative;
				padding: 16px 24px 20px 24px;

				h3 {
					width: fit-content;
					box-shadow: inset 0 -8px 0 #fee500;
				}
			}

			.text-inner {
				display: flex;
			}
		}
	}

	&__contents {
		padding-top: 123px;
		background-color: #ebebeb;
		padding-bottom: 53px;
		.info-area {
			display: flex;

			.icon-hospital {
				width: 60px;
				height: 60px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				margin-right: 16px;
				background-color: #fff;

				&::before {
					content: "";
					display: inline-block;
					width: 42px;
					height: 38px;
					background: url(../img/icons/icon-hospital.svg) center center no-repeat;
				}
			}

			.info {
				width: 332px;
				font-size: 12px;
				line-height: normal;
				letter-spacing: -0.3px;
				margin: 0;

				span {
					font-weight: bold;
				}
				.highlight {
					color: #44bbcd;
				}
			}
		}

		.banner-slider {
			margin: -6px 0 20px 0;
			.slick-list {
				max-height: 100px;
			}
			.slick-slide img {
				min-height: 100px;
				border-radius: 8px;
			}
		}
	}

	&__hospital {
		margin: 30px 0;
		.button-area {
			.btn {
				background-color: #282828;
				color: #fff;
			}
		}
	}

	&__list {
		.list-area {
			margin-bottom: 24px;
			padding: 18px 24px 24px;
			border-radius: 16px;
			background-color: #fff;
		}

		.list-title {
			display: flex;
			align-items: center;
			justify-content: space-between;

			button {
				display: flex;
				align-items: center;
				padding: 6px 13px 8px 8px;
				border-radius: 16px;
				border: solid 1px #e0e0e0;
				font-size: 12px;
				font-weight: 500;
				letter-spacing: -0.3px;
			}
		}

		.photo-area {
			padding-bottom: 20px;
			border-bottom: 1px solid #e0e0e0;
		}

		.info-area {
			padding-top: 20px;
			p {
				margin: 0;
				font-size: 14px;
				line-height: 1.57;
				letter-spacing: -0.35px;
			}
		}

		.button-area {
			margin-top: 24px;
			button {
				background-color: #e2f4f7;
			}
		}
	}

	&__button {
		margin-top: 1.5rem;
		button {
			width: 100%;
			padding: 15px 0;
			background: #ddd;
		}
	}
}

// upload page
.analysis-upload {
	.btn-area {
		img {
			width: 36px;
			height: 36px;
		}
		span {
			display: block;
			font-size: 12px;
			line-height: 1.83;
			letter-spacing: -0.3px;
		}
	}

	&__cam {
		width: 100%;
		height: 100%;
	}

	// photo
	&__photo {
		height: 100%;
		background-color: #fff;

		.btn-wrap {
			padding: 0 20px;
		}

		&--info {
			padding: 12px 0 20px 0;
			margin: 0;
			text-align: center;
			font-size: 16px;
			letter-spacing: -0.4px;
			background-color: #fff;
		}

		&--button {
			padding-bottom: 20px;
			margin-top: 0;
			justify-content: center;
			background-color: #fff;

			.btn {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 27px;
				font-size: 14px;
				color: #282828;
				font-weight: 500;
			}
			.btn::before {
				content: "";
				display: inline-block;
				width: 20px;
				height: 20px;
				margin-right: 4px;
			}

			.btn-refresh {
				position: relative;
			}
			.btn-refresh::after {
				content: "";
				width: 1px;
				display: block;
				background: #e0e0e0;
				height: 16px;
				opacity: 1;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translate(0, -50%);
			}
			.btn-refresh::before {
				background: url(../img/icons/icon-camera-all.svg) center center no-repeat;
			}

			.btn-retake::before {
				background: url(../img/icons/icon-camera.svg) center center no-repeat;
			}
		}

		.btn-bottom__single .btn-retake {
			padding-top: 12px;
		}

		.photo-area img {
			width: 100%;
		}
	}

	// camera
	&__camera {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		min-height: 160px;
		max-height: 160px;
		background-color: #fff;

		.camera-area {
			position: relative;

			&:after {
				content: "";
				display: inline-block;
				width: 64px;
				height: 64px;
				border-radius: 50%;
				box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
				z-index: 1;
				background-color: #fff;
			}

			button {
				position: absolute;
				top: 4px;
				left: 50%;
				transform: translate(-50%, 0);
				width: 56px;
				height: 56px;
				border: 4px solid transparent;
				border-radius: 50%;
				border-color: #44bbcd;
			}
		}

		.camera-controls {
			position: relative;
			display: flex;
			justify-content: center;
			margin: 0 0 30px 0;

			&__align {
				width: calc(100% - 120px);
				padding: 0 60px;
				text-align: center;
				align-items: center;
				justify-content: space-between;
			}
		}

		.guideline-state {
			display: flex;
			justify-content: center;
			padding: 10px 0 16px 0;
			p {
				margin: 0;
				letter-spacing: -0.4px;
				font-size: 16px;
				font-weight: 500;
				text-align: center;
			}
		}
	}

	// guide line
	.guideline-wrap {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;

		.guideline {
			width: 100%;
			height: 100%;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;
		}

		// center guide img
		.center-cancel {
			background-image: url(../img/skin-analysis/center-cancel.png);
		}
		.center-ok {
			background-image: url(../img/skin-analysis/center-ok.png);
		}

		// left guide img
		.left-cancel {
			background-image: url(../img/skin-analysis/left-cancel.png);
		}
		.left-ok {
			background-image: url(../img/skin-analysis/left-ok.png);
		}

		// right guide img
		.right-cancel {
			background-image: url(../img/skin-analysis/right-cancel.png);
		}
		.right-ok {
			background-image: url(../img/skin-analysis/right-ok.png);
		}

		// body guideline
		.body {
			background-image: url(../img/skin-analysis/body-guideline.png);
		}
	}
}

// ar result page
.ar-result .common-header.full-header {
	padding-inline: inherit;
}

// guide popup
.guide-popup {
	.swiper-slide {
		padding: 122px 20px 50px 20px;
	}
}

// analysis-popup
.analysis-popup {
	--height: 460px;
}

.reverse {
	transform: scaleX(-1) !important;
}

// media query (mobile)
@media screen and (max-width: 768px) {
	.analysis-result__wrap .title-area {
		height: 238px;
	}

	.analysis-result__change .title-area {
		height: 80px;
	}

	.analysis-result__wrap .title-area .title h2 {
		max-width: 290px;
	}

	.analysis-result__wrap .photo-area img {
		height: 100%;
	}

	.analysis-result__change .body-img .img-area {
		height: 240px;
	}

	.analysis-result__summary .summary-area {
		top: -75px;
		max-height: 198px;
		min-height: 158px;
	}

	.analysis-result__contents .info-area .info {
		width: 204px;
	}

	.block {
		display: block;
	}

	.analysis-upload .content-margin {
		margin-left: calc((100vw - 480px) / 2);
		margin-right: calc((100vw - 480px) / 2);
	}

	// center guide img
	.guideline-wrap .center-cancel {
		background-image: url(../img/skin-analysis/center-cancel_m.png);
	}
	.guideline-wrap .center-ok {
		background-image: url(../img/skin-analysis/center-ok_m.png);
	}

	// left guide img
	.guideline-wrap .left-cancel {
		background-image: url(../img/skin-analysis/left-cancel_m.png);
	}
	.guideline-wrap .left-ok {
		background-image: url(../img/skin-analysis/left-ok_m.png);
	}

	// right guide img
	.guideline-wrap .right-cancel {
		background-image: url(../img/skin-analysis/right-cancel_m.png);
	}
	.guideline-wrap .right-ok {
		background-image: url(../img/skin-analysis/right-ok_m.png);
	}
}

@media screen and (max-width: 767px) {
	.analysis-result__wrap .summary .photo-area {
		max-height: 96px;
	}

	.analysis-result__contents {
		padding-top: 109px;
	}
}

@media screen and (min-width: 767px) {
	.analysis-upload .common-header {
		padding-inline: var(--pc-padding-horizontal);
	}
}

/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

ion-segment {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

ion-segment ion-segment-button {
	/* --background: #fff; (버그발생)*/
	font-size: 16px;
	font-weight: 500;
}

ion-segment ion-segment-button::part(native) {
	padding-left: 26px;
	padding-right: 26px;
}

ion-content {
	--background: transparent;
}

.app {
	background-color: #f4f4f4;
}

.no-result {
	text-align: center;
	padding-top: 60px;
	font-size: 14px;
	color: #777;
	font-weight: 400;
}
ion-segment::-webkit-scrollbar {
	display: none; /* Chrome, Safari, Opera*/
}

/*액션시트 css*/
.action-sheet-button.sc-ion-action-sheet-ios {
	font-size: 16px;
	background-color: #fff;
}
.action-sheet-selected.sc-ion-action-sheet-ios span {
	color: #44bbcd;
}
ion-back-button ion-icon {
	display: none;
}

ion-back-button {
	color: #282828;
	--color: #282828;
	width: 25px;
	position: absolute;
}

.wrap-etc-icon {
	background-color: #44bbcd;
	width: 40px;
	height: 40px;
	padding: 8px;
	border-radius: 100%;
	position: absolute;
	right: 20px;
}

ion-toast {
	--max-width: 460px;
	--background: #282828;
	--color: #fff;
	opacity: 0.8;
}

/*alert 디자인*/
.sc-ion-alert-ios-h {
	--width: 280px;
	--max-width: 280px;
}
.alert-wrapper.sc-ion-alert-ios {
	border-radius: 16px;
}
.alert-button-group.sc-ion-alert-ios {
	height: 53px;
}
.alert-button.sc-ion-alert-ios {
	font-size: 16px;
	font-weight: 500;
	height: 100%;
}
.primary.alert-button.sc-ion-alert-ios .alert-button-inner {
	color: #44bbcd;
}

.alert-css1 {
	--height: 128px;
	font-size: 16px;
	font-weight: 500;
}
.alert-css1 .alert-message {
	margin-bottom: 27px;
	height: 24px;
	padding-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
}

.alert-css1 .alert-head {
	height: 24px;
}

.alert-css2 {
	font-size: 16px;
	font-weight: 500;
}

.alert-css2 .alert-message {
	margin-bottom: 29px;
	/* height: 48px; */
	padding-bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
}

.alert-css2 .alert-head {
	height: 30px;
}

.alert-button .alert-button-inner {
	font-size: 16px;
	font-weight: 500;
	color: #44bbcd;
}

.alert-button:first-child .alert-button-inner {
	color: #ff7473;
}

/* input list */
.error-message {
	color: #44bbcd;
	font-size: 12px;
	margin-top: 6px;
	text-align: left;
}
.success-message {
	color: #40e593;
	font-size: 12px;
	margin-top: 6px;
	text-align: left;
}

.ion-page ion-select.error,
.ion-page ion-input.error {
	border-bottom: solid 2px #44bbcd;
}

.sc-ion-input-ios-h,
ion-select {
	opacity: 1;
	--placeholder-opacity: 1;
	--placeholder-color: #e0e0e0;
}

.login-page-no {
	z-index: 1;
	position: absolute;
	right: 20px;
	top: 17.2px;
	font-size: 14px;
}

.pg-mypage-info .title-sub {
	background-color: #e2f4f7;
	font-size: 16px;
	text-align: left;
	margin: 0;
	padding-top: 20px;
	padding-bottom: 20px;
}

.pg-mypage-info ion-select,
.pg-sign-up ion-select {
	background-image: url("../img/icons_arrow_down.png");
	background-size: 24px;
	background-repeat: no-repeat;
	background-position-x: right;
	background-position-y: center;
	height: auto;
}

.pg-mypage-info ion-select,
.pg-mypage-info ion-input,
.pg-sign-up ion-select,
.pg-sign-up ion-input {
	border-bottom: solid 1px #e0e0e0;
	color: #282828;
	font-weight: 500;
	font-size: 16px;
}

.pg-mypage-info ion-label,
.pg-mypage-info .label-stacked.sc-ion-label-ios-h,
.pg-sign-up .item ion-label,
.pg-sign-up .label-stacked.sc-ion-label-ios-h {
	font-size: 12px;
	font-weight: bold;
	margin-bottom: 0;
}

.pg-sign-up ion-item {
	--background-hover: transparent;
	--background-activated: transparent;
}

.pg-sign-up .item ion-label {
	margin: 0;
}

.pg-mypage-info input,
.pg-mypage-info .native-input.sc-ion-input-ios,
.pg-sign-up input,
.pg-sign-up .native-input.sc-ion-input-ios {
	font-size: 14px;
	font-weight: 500;
}

.icon-space {
	height: 25px;
	width: 25px;
	margin-bottom: 5px;
	margin-top: 5px;
}

/*작은 체크박스*/
.check ion-checkbox {
	margin-right: 10px;
	height: 18px;
	width: 18px;
	vertical-align: middle;
}

.tab-etc {
	padding-top: 16px;
	padding-bottom: 16px;
}
.tab-etc ion-item {
	font-size: 16px;
	font-weight: 500;
	color: #282828;
	width: 100%;
	height: 56px;
	--min-height: 56px;
}

.tab-etc ion-item img {
	margin-left: 20px;
	margin-right: 20px;
}

/*메뉴탭 - 홈*/
.tab-home .icon-space {
	background-image: url("../img/tab_home.png");
	background-size: 24px;
	background-repeat: no-repeat;
	background-position-x: center;
}

.tab-home:hover .icon-space,
.tab-home.tab-selected .icon-space {
	background-image: url("../img/tab_home_hover.png");
}

/*메뉴탭 - 피부분석*/
.tab-skin-analysis .icon-space {
	background-image: url("../img/tab_analysis.png");
	background-size: 24px;
	background-repeat: no-repeat;
	background-position-x: center;
}

.tab-skin-analysis:hover .icon-space,
.tab-skin-analysis.tab-selected .icon-space {
	background-image: url("../img/tab_analysis_hover.png");
}

/*메뉴탭 - 피부변화*/
.tab-skin-change .icon-space {
	background-image: url("../img/tab_dermal_change.png");
	background-size: 100%;
	background-repeat: no-repeat;
	background-position-x: center;
}

.tab-skin-change:hover .icon-space,
.tab-skin-change.tab-selected .icon-space {
	background-image: url("../img/tab_dermal_change_hover.png");
}

/*메뉴탭 - 피부기록*/
.tab-skin-log .icon-space {
	background-image: url("../img/tab_history.png");
	background-size: 22px;
	background-repeat: no-repeat;
	background-position-x: center;
}

.tab-skin-log:hover .icon-space,
.tab-skin-log.tab-selected .icon-space {
	background-image: url("../img/tab_history_hover.png");
}

/* 메뉴탭 - 병원찾기 */
.tab-skin-hospital .icon-space {
	background-image: url("../img/tab_hospital.svg");
	background-size: 24px;
	background-repeat: no-repeat;
	background-position-x: center;
}

.tab-skin-hospital:hover .icon-space,
.tab-skin-hospital.tab-selected .icon-space {
	background-image: url("../img/tab_hospital_hover.svg");
}

.etc-icon {
	background-image: url("../img/icons_add.png");
	background-size: 24px;
	background-repeat: no-repeat;
}

.etc-icon-selected {
	background-image: url("../img/icons_close.png");
	background-size: 24px;
	background-repeat: no-repeat;
}

/*준비중입니다*/
.pg-prepare .content-inner {
	display: flex;
	justify-content: center;
	align-items: center;
}

.prepare-page {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.prepare-page h2 {
	font-weight: bold;
	font-size: 20px;
	margin-bottom: 20px;
	color: #44bbcd;
}

.prepare-page p {
	font-size: 16px;
	margin: 0;
}
.prepare-page ion-icon {
	color: #44bbcd;
	height: 60px;
	width: 60px;
	margin-bottom: 10px;
}

/*검색창*/
ion-searchbar.sc-ion-searchbar-md-h,
ion-searchbar.sc-ion-searchbar-ios-h {
	--box-shadow: none;
	border: solid 2px #44bbcd;
	border-radius: 8px;
	padding: 0;
	height: 44px;
	margin-bottom: 5px;
	text-align: left;
}

.searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
	font-size: 16px;
	-webkit-padding-start: 16px;
	padding-inline-start: 16px;
	-webkit-padding-end: 30px;
	padding-inline-end: 30px;
	padding-bottom: 2px;
}

.searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-search-icon.sc-ion-searchbar-ios {
	-webkit-margin-start: 10px;
	margin-inline-start: 10px;
}

.search.icon {
	position: absolute;
	right: 8px;
}
.searchbar-input.sc-ion-searchbar-ios {
	background-color: transparent;
}

.searchbar-search-icon {
	display: none !important;
}

.searchbar-clear-button.sc-ion-searchbar-ios {
	margin-right: 45px;
}

.searchbar-clear-button ion-icon {
	padding: 0;
}

/*====================================home====================================*/
.home h2 {
	margin-bottom: 20px;
}
.home h2:nth-of-type(2) {
	margin-top: 30px;
}

.home h2:nth-of-type(3) {
	margin-top: 15px;
}

.home .title-container {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.home ion-header ion-toolbar {
	border: transparent;
	--border-color: transparent;
}

.home ion-header ion-toolbar ion-title {
	font-size: 24px;
	font-weight: 700;
	color: #44bbcd;
	align-items: center;
	padding-top: 5px;
	padding-right: 20px;
	padding-left: 20px;
}

.home .result-grid {
	margin-bottom: 20px;
}

.home .result-grid ion-row:nth-of-type(2) ion-col {
	padding-top: 10px;
}

.home .result-grid ion-row:nth-of-type(2) ion-col:nth-of-type(1) {
	padding-right: 5px;
}

.home .result-grid ion-row:nth-of-type(2) ion-col:nth-of-type(2) {
	padding-left: 5px;
}

.home .btn-skin-result {
	height: 45px;
	--border-radius: 8px;
	--background: #e2f4f7 !important;
	--background-activated: #d6eff3 !important;
	--background-focused: #d6eff3 !important;
	--background-hover: #d6eff3 !important;
	color: #282828;
	margin: 0;
}

/** Ionic CSS Variables **/
.home .slide-banner {
	height: 260px;
	margin-bottom: 10px;
}

.home .slide-banner ion-card {
	width: 100%;
	/* max-width: 480px; */
	height: 200px;
	border-radius: 12px;
	margin-top: 0;
	cursor: pointer;
}

.home .slide-banner img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	transition: 600ms all ease-in-out;
}

.home .slide-banner .swiper-slide-active {
	transform: scale(1.05, 1.05);
}

.home .slide-banner .swiper-slide {
	transition: 400ms all ease-in-out;
	align-items: flex-start;
	margin-top: 15px;
}

.home .slide-banner .swiper-pagination-bullet {
	background: #646464;
}

.home .slide-banner .swiper-pagination-bullet-active {
	background-color: #44bbcd;
}

.home .slide-video ion-slide img {
	top: 0;
	border-radius: 8px;
	width: 100%;
}

.home .slide-video ion-slide .time {
	position: absolute;
	bottom: 15px;
	right: 15px;
	border-radius: 5px;
	height: 20px;
	width: 50px;
	background-color: #282828;
	color: #fff;
	font-size: 12px;
}

.home .slide-video ion-slide h4 {
	position: absolute;
	top: 85%;
	left: 50%;
	width: 100%;
	transform: translate(-50%, -50%);
	margin: 0;
}

.home .slide-video .video-container iframe,
.home .slide-video .video-container object,
.home .slide-video .video-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 8px;
}

.home .slide-video .react-player__preview {
	border-radius: 8px;
}

.home .all-slide-video {
	position: relative;
	color: #282828;
	font-size: 16px;
	cursor: pointer;
}

.home .btn-skin-result .txt-in-btn {
	text-align: left;
	width: 100%;
	font-size: 14px;
	padding-left: 10px;
	padding-bottom: 2px;
}

.home .btn-skin-result .impt {
	color: #44bbcd;
	font-weight: 700;
}

.home .btn-skin-result img {
	width: 20px;
	height: 20px;
}

.home .btn-skin-analysis,
.home .btn-skin-change {
	font-size: 16px;
	height: 120px;
	--background: #fff;
	--background-activated: #fff !important;
	--background-focused: #fff !important;
	--background-hover: #fff !important;
	color: #282828;
	border: solid 2px #e0e0e0;
	border-radius: 8px;
	margin: 0;
	margin-bottom: 3px;
	padding: 2px;
}

.home .btn-skin-analysis:hover,
.home .btn-skin-change:hover {
	border: solid 2px #44bbcd;
}

.home .btn-skin-analysis img {
	height: 55px;
}

.home .btn-skin-change img {
	height: 55px;
}

.home .btn-skin-analysis .button-inner,
.home .btn-skin-change .button-inner {
	flex-flow: column;
}
.button-inner {
	flex-flow: column;
}
.home .grid-clinic ion-item {
	--min-height: 100px;
	padding-right: 0;
	padding-left: 0;
}

.home .grid-clinic ion-avatar,
.home .grid-clinic ion-avatar > img {
	border-radius: 8px;
	height: 80px;
	width: 80px;
}

.home .grid-clinic ion-label h4 span {
	background-color: #44bbcd;
	border-radius: 8px;
	color: #fff;
	padding-right: 5px;
	padding-left: 5px;
	font-size: 12px;
	font-weight: 500;
}

.home .grid-clinic ion-label h3 {
	color: #282828;
	font-weight: 700;
}

.home .grid-clinic ion-label p {
	color: #c6c6c6;
	font-weight: 500;
}

.home .grid-skin-dict {
	margin-bottom: 15px;
}
.home .grid-skin-dict ion-col {
	text-align: center;
	cursor: pointer;
}

.home .grid-skin-dict img {
	height: 40px;
	margin: 10px;
}

.home .grid-skin-dict h4 {
	text-align: center;
	margin-top: 5px;
}

.home .footer {
	padding-top: 10px;
	padding-bottom: 15px;
}

.home .footer div {
	color: #b5b5b5;
}

.home .footer address {
	font-size: 12px;
	font-style: normal;
	font-weight: 100;
}

/*====================================skin-video====================================*/

ion-tab-bar {
	overflow: auto;
}
.menu-wrap {
	position: relative;
	z-index: 1;
}
.menu-wrap ion-segment {
	--background: #fff;
}

.menu-wrap img.icon {
	cursor: pointer;
	margin-top: 12px;
}

.menu-all {
	position: absolute;
	top: 0;
	right: 0;
	background-color: #fff;
	line-height: 48px;
	height: 48px;
	padding-right: 20px;
	padding-left: 40px;
	background: linear-gradient(to left, #fff 80%, rgba(255, 255, 255, 0));
	z-index: 2;
}

.menu-before,
.menu-next {
	display: none;
	z-index: 2;
}

.list-md .item-lines-none {
	border-bottom: solid 1px #dedede;
}

.video-item .label-icon ion-label {
	margin: 0;
}

ion-button.btn-ask {
	margin: 0;
	height: 90px;
	margin-top: 10px;
}

.video-item ion-avatar,
.video-item ion-avatar img {
	border-radius: 10px;
	height: 80px;
	width: 130px;
}

ion-segment.wrap {
	flex-wrap: wrap;
	justify-content: flex-start;
}

.pg-skin-video ion-item img.clinic,
.pg-video-detail ion-item img.clinic {
	width: 154px;
	height: 86px;
	object-fit: cover;
	border-radius: 8px;
}
.pg-skin-video .order {
	--min-height: auto;
	padding-top: 5px;
}
.pg-skin-video .order ion-label {
	font-size: 14px;
	margin: 0;
}
.pg-skin-video .order .icon {
	margin: 0;
}
.pg-skin-video ion-list > ion-item:first-of-type {
	padding-top: 0;
}
.pg-skin-video h5 {
	font-size: 14px;
	color: #282828;
}

.pg-skin-video h4 {
	font-size: 16px;
	font-weight: 500;
}

.pg-skin-video .btn-ask,
.pg-video-detail .btn-ask,
.pg-skin-info .btn-ask {
	background-color: #7bd2de;
	color: #282828;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.pg-skin-info .btn-ask,
.pg-skin-video .btn-ask {
	padding-top: 5px;
}
.pg-video-detail .btn-ask {
	padding-top: 15px;
	padding-bottom: 15px;
	border-radius: 12px;
}

.pg-skin-info .btn-ask img,
.pg-skin-video .btn-ask img,
.pg-skin-video .btn-ask img {
	height: 100px;
}

.pg-video-detail .btn-ask {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.pg-skin-info .btn-ask p,
.pg-skin-video .btn-ask p,
.pg-video-detail .btn-ask p {
	color: #282828;
	margin: 0;
}

.pg-skin-info .btn-ask p ion-icon,
.pg-skin-video .btn-ask p ion-icon,
.pg-video-detail .btn-ask p ion-icon {
	width: 24px;
}

.pg-skin-info .btn-ask p:nth-child(1),
.pg-skin-video .btn-ask p:nth-child(1) {
	font-size: 14px;
	font-weight: 500;
}

.pg-skin-info .btn-ask p:nth-child(2),
.pg-skin-video .btn-ask p:nth-child(2) {
	font-size: 20px;
	font-weight: 600;
}

.pg-skin-info .btn-ask p:nth-child(3),
.pg-skin-video .btn-ask p:nth-child(3) {
	display: flex;
	align-items: center;
	font-size: 12px;
	color: #fff;
	font-weight: 600;
	margin-top: 4px;
}

.pg-video-detail .btn-ask p:nth-child(1) {
	font-size: 12px;
	font-weight: 500;
}

.pg-video-detail .btn-ask p:nth-child(2) {
	font-size: 14px;
	font-weight: 600;
}
.pg-video-detail ion-grid ion-row:first-of-type h4.hashtag {
	font-size: 14px;
	font-weight: 500;
	margin-top: 5px;
}

.pg-video-detail .description p {
	margin-top: 0;
	margin-bottom: 16px;
}

.hashtag span {
	cursor: pointer;
}

/*피부영상 병명 탭*/
.menu {
	height: 49px;
	padding-left: 20px;
	border-bottom: solid 1px #e0e0e0;
}

.menu ion-segment-button {
	--indicator-height: 3px;
}
.menu ion-segment-button::part(indicator) {
	opacity: 0;
}
.menu ion-segment-button::part(indicator-background) {
	background: transparent;
}

.menu ion-segment-button::part(native) {
	color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.6);
}

.menu ion-segment-button.selected::part(indicator-background),
.menu ion-segment-button.active::part(indicator-background) {
	background: var(--indicator-color);
}

.menu ion-segment-button[aria-selected="false"]::part(indicator-background) {
	background: transparent;
}

.menu ion-segment-button.selected::part(indicator),
.menu ion-segment-button.active::part(indicator) {
	opacity: 1;
}
.menu ion-segment-button.selected::part(native),
.menu ion-segment-button.active::part(native) {
	color: #44bbcd;
}
.menu ion-segment-button[aria-selected="false"]::part(native) {
	color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.6);
}

.menu-wrap .menu-contents {
	position: absolute;
	padding: 10px 15px;
	box-shadow: 0 5px 20px 0 #00000029;
}

.wrap {
	border-bottom-left-radius: 16px;
	border-bottom: 0;
	border-bottom-right-radius: 16px;
	background-color: #fff;

	width: 100%;
	z-index: 2;
	left: 0;
	right: 0;
}

.wrap ion-chip {
	margin: 5px;
	padding: 7px 16px 9px;
	border-color: #c6c6c6;
	border-radius: 20px;
}

.wrap ion-chip.selected {
	border-color: #44bbcd;
	background-color: #44bbcd;
}

.wrap ion-chip ion-label.ion-color.sc-ion-label-md-h {
	color: #c6c6c6;
}

.wrap ion-chip.selected ion-label.ion-color.sc-ion-label-md-h {
	color: #fff;
}

.pg-video-detail .padding-bottom-0 {
	padding-bottom: 0;
}

.pg-video-detail .padding-0 {
	padding: 0;
}

.pg-video-detail video {
	width: 100%;
	background-color: #e0e0e0;
}

.pg-video-detail .video-item {
	padding: 0;
}

/*모바일*/
.app .pg-video-detail .video-thumbnail-wrapper {
	position: relative;
	height: 0;
	padding-bottom: 56.25%;
}

.app .pg-video-detail .video-thumbnail {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.pg-video-detail h2 {
	color: #282828;
	font-weight: 700;
	font-size: 20px;
}

.pg-video-detail h3 {
	color: #282828;
	font-weight: 700;
	font-size: 16px;
}

.pg-video-detail h4 {
	color: #282828;
	font-weight: 600;
	font-size: 18px;
}

.pg-video-detail p {
	color: #282828;
	font-weight: 500;
	font-size: 14px;
}

.pg-video-detail ion-avatar,
.pg-video-detail ion-avatar > img {
	border-radius: 100%;
	height: 60px;
	width: 60px;
}

.pg-video-detail .txt-in-btn {
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
}

.pg-video-detail .impt {
	font-weight: 600;
	font-size: 16px;
}

.pg-video-detail ion-grid ion-row:first-of-type h4 {
	font-size: 16px;
}

.pg-video-detail ion-grid ion-row:first-of-type p {
	color: #c6c6c6;
	margin: 0;
}

.pg-video-detail ion-grid ion-row:first-of-type ion-item {
	margin-top: 6px;
	margin-bottom: 6px;
}
.pg-video-detail ion-grid ion-row:first-of-type .bookmark {
	position: absolute;
	top: 10px;
	right: 0;
}

/*====================================skin-dictionary====================================*/

.pg-skin-dict ion-list img.icon {
	margin-right: 20px;
}

.pg-skin-dict ion-list ion-item {
	border-bottom: solid 1px #e0e0e0;
	font-size: 16px;
}

.pg-skin-dict ion-list .item.sc-ion-label-ios-h,
.pg-skin-dict .item .sc-ion-label-ios-h {
	font-size: 16px;
}

.pg-search ion-list ion-item.btn-more {
	border-top: solid 1px #e0e0e0;
	border-bottom: solid 1px #e0e0e0;
}

.pg-search .pg-skin-info ion-item.btn-more {
	border-top: 0;
	border-bottom: 0;
}

.pg-search ion-list ion-item.btn-more.clinic {
	border-top: none;
}

.pg-skin-dict ion-list ion-item.btn-more {
	border-bottom: none;
}

.pg-skin-video ion-list ion-item.btn-more,
.pg-search ion-list ion-item.btn-more,
.pg-skin-dict ion-list ion-item.btn-more {
	--inner-padding-end: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	cursor: pointer;
}

.pg-skin-video ion-list ion-item.btn-more:hover p,
.pg-search ion-list ion-item.btn-more:hover p,
.pg-skin-dict ion-list ion-item.btn-more:hover p {
	color: #282828;
}

.pg-skin-video ion-list ion-item.btn-more p,
.pg-search ion-list ion-item.btn-more p,
.pg-skin-dict ion-list ion-item.btn-more p {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: 0 0 0 20px;
	text-align: center;
	color: #777;
}

.pg-skin-video ion-list ion-item.btn-more em,
.pg-search ion-list ion-item.btn-more em,
.pg-skin-dict ion-list ion-item.btn-more em {
	display: inline-block;
	width: 24px;
	height: 24px;
	background-image: url("../img/icons_arrow_down.png");
	background-size: 100%;
}
.pg-skin-dict ion-list ion-item ion-label {
	margin-top: 20px;
	margin-bottom: 20px;
}

.pg-skin-dict-detail h1 {
	font-size: 20px;
	font-weight: 600;
}

.pg-skin-dict-detail h2 {
	font-size: 20px;
	font-weight: 600;
}
.pg-skin-dict-detail h3 {
	font-size: 18px;
	font-weight: normal;
}

.pg-skin-dict-detail p {
	font-size: 16px;
	color: #555;
	margin-bottom: 0;
}

.pg-skin-dict-detail .symptom-row ion-col {
	text-align: center;
}

.pg-skin-dict-detail ion-grid:nth-of-type(even) {
	background-color: #f8f8f8;
	border-radius: 12px;
	margin-right: 20px;
	margin-left: 20px;
	margin-bottom: 30px;
	padding-top: 20px;
}

.pg-skin-dict-detail ion-grid:nth-of-type(even) ion-list {
	background-color: #f8f8f8;
}
.pg-skin-dict-detail ion-grid:last-of-type {
	margin-bottom: 0;
	padding-bottom: 5px;
}
.pg-skin-dict-detail ion-grid:first-of-type {
	margin-top: 0;
}
.pg-skin-dict-detail .content-inner {
	padding-bottom: 20px;
}
.pg-skin-dict-detail ion-grid {
	padding-bottom: 30px;
}

.pg-skin-dict-detail .grid-cause {
	padding-bottom: 40px;
}

.pg-skin-dict-detail .grid-cause h3 {
	margin-top: -40px;
}
/*====================================login-base====================================*/
.pg-login-base {
	text-align: center;
}

.pg-login-base .login-logo {
	position: relative;
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-bottom: 70%;
}

.pg-login-base .logo {
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
}

.pg-login-base ion-content ion-button {
	margin-bottom: 10px;
}

.pg-login-base .divide {
	color: #c6c6c6;
	font-size: 14px;
}
.pg-login-base p {
	padding-top: 14px;
	margin: 0;
	font-size: 14px;
	color: #282828;
}
.pg-login-base h1 {
	font-size: 36px;
	color: #44bbcd;
	font-weight: 900;
}
.pg-login-base h2 {
	font-size: 14px;
	font-weight: 500;
	color: #282828;
}

.pg-login-base .reg {
	font-weight: 700;
	color: #44bbcd;
	padding-left: 8px;
	cursor: pointer;
}

.btn-kakao,
.btn-naver,
.btn-google,
.btn-email {
	height: 48px;
	font-size: 15px;
}

.btn-kakao {
	--background: #fee500;
	--background-activated: #fee500;
	color: black;
	height: 48px;
}

.btn-naver {
	--background: #2db400;
	--background-activated: #fee500;
	color: #fff;
	height: 48px;
}

.btn-google {
	--background: #fff;
	--background-activated: #fff;
	color: #282828;
	--border-color: #e0e0e0;
	--border-style: solid;
	--border-width: 1px;
	height: 48px;
}

.btn-kakao img,
.btn-naver img,
.btn-google img {
	vertical-align: middle;
}

.btn-kakao img,
.btn-naver img,
.btn-google img,
.btn-email img {
	margin-right: 4px;
}

/*====================================verification====================================*/
.pg-vrf {
	text-align: center;
}

.pg-vrf ion-row:nth-of-type(1) {
	padding-top: 70px;
}

.pg-vrf ion-row:nth-of-type(2) {
	padding-top: 80px;
	padding-bottom: 30px;
}

.pg-vrf .title {
	font-size: 24px;
	font-weight: bold;
	text-align: left;
}
.pg-vrf .content-main {
	font-weight: bold;
	font-size: 20px;
	margin-bottom: 20px;
}
.pg-vrf .content-sub {
	font-size: 16px;
}

.pg-vrf .icon-complete {
	color: #44bbcd;
	height: 60px;
	width: 60px;
	margin-bottom: 10px;
}

.pg-sign-up-complete .content-main {
	color: #44bbcd;
}

/*====================================terms====================================*/
.pg-terms {
	text-align: center;
}

.pg-terms ion-item {
	border-bottom: solid 1px #e0e0e0;
}

.pg-terms ion-row:nth-of-type(2) {
	padding-top: 20px;
	padding-bottom: 40px;
}

.pg-terms .title {
	font-size: 24px;
	font-weight: bold;
	text-align: left;
}
.pg-terms .title-sub {
	font-size: 16px;
	margin-top: 20px;
	text-align: left;
}
.pg-terms .label {
	font-size: 14px;
	margin-top: 15px;
	margin-bottom: 15px;
	color: #777;
}

.pg-terms ion-list ion-item:first-of-type .label {
	font-size: 16px;
	font-weight: 500;
	color: #282828;
}

.pg-terms ion-list ion-item ion-checkbox {
	height: 22px;
	width: 22px;
	margin-left: 10px;
	margin-right: 10px;
}

.pg-terms ion-list ion-item:first-of-type ion-checkbox {
	height: 26px;
	width: 26px;
	margin-left: 0;
}

.pg-terms .detail {
	z-index: 11;
	font-size: 12px;
	border-bottom: solid 1px;
	color: #777;
	cursor: pointer;
}

.modal-terms ion-content {
	border-top: solid 1px #e0e0e0;
}

/*====================================회원가입/로그인 관련 페이지====================================*/

.pg-sign-up .title {
	font-size: 24px;
	font-weight: bold;
	text-align: left;
}
.native-input[disabled].sc-ion-input-ios {
	opacity: 1;
	color: #e0e0e0;
	background-color: #fff;
}

.pg-sign-up .chck {
	margin-right: 0;
	font-size: 12px;
	z-index: 100;
	cursor: pointer;
	border: solid 1px #44bbcd;
	color: #44bbcd;
	padding: 2px 8px;
	background: #fff;
	border-radius: 50px;
	align-self: center;
}

.pg-sign-up .nknm-box {
	position: relative;
}

.pg-sign-up .chck.nknm {
	position: absolute;
	right: 0;
	top: 32px;
}

.pg-sign-up .input-email {
	max-width: 90%;
}

.pg-sign-up .item-email-end {
	--min-height: 35px;
	margin-top: 10px;
}

.pg-sign-up .item-email-end ion-label {
	display: none;
}

.pg-sign-up .select-email {
	--min-height: auto;
	font-size: 14px;
	margin-top: 10px;
}
.mt-24 {
	margin-top: 24px;
}
.pg-sign-up .find span {
	display: inline-block;

	padding: 0 10px;
	font-size: 14px;
	font-weight: normal;
	color: #282828;
}
.pg-sign-up .find span span {
	cursor: pointer;
}
.pg-sign-up .find em {
	display: inline-block;
	width: 1px;
	height: 15px;
	margin: 0 8px;
	background-color: #777;
}
.pg-sign-up .find {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
	text-align: center;
}
.pg-sign-up .email {
	position: absolute;
	right: 0;
	top: 28px;
	color: #282828;
}
.pg-sign-up ion-list:first-of-type {
	margin-top: 6px;
}

.pg-sign-up ion-list {
	margin-top: 5px;
	margin-bottom: 14px;
	clear: both;
}

/*====================================EmailLogin====================================*/

.pg-email-login ion-list:first-of-type {
	margin-top: 70px;
}

.pg-email-login .check {
	display: flex;
}

/*====================================비밀번호찾기.pg-survey ion-list ion-item ion-checkbox====================================*/

.pg-find-pw ion-row:nth-of-type(2) {
	padding-top: 40px;
}

.pg-find-email .content-main {
	margin-bottom: 0;
}

.pg-reset-pw ion-list:last-of-type {
	margin-bottom: 40px;
}
/*====================================MyPage====================================*/

.pg-mypage ion-list ion-item:first-of-type {
	font-size: 14px;
	padding-left: 35px;
	background-color: #e2f4f7;
	--background: #e2f4f7;
	align-items: flex-end;
	border-bottom: none;
}
.pg-mypage ion-list ion-item:first-of-type ion-label {
	padding-top: 10px;
	padding-bottom: 5px;
}
.pg-mypage ion-list ion-item:first-of-type ion-label p {
	font-size: 16px;
	color: #282828;
}

.pg-mypage ion-list ion-item:first-of-type img {
	margin-top: 5px;
	height: 110px;
}
.pg-mypage ion-list ion-item {
	border-bottom: solid 1px #e0e0e0;
	font-size: 16px;
}
.pg-mypage ion-list ion-item ion-label {
	padding-top: 10px;
	padding-bottom: 10px;
	opacity: 1 !important;
}
.pg-mypage ion-list ion-item img.icon {
	margin-right: 20px;
}
.pg-mypage h2 {
	font-size: 18px;
	font-weight: 600;
}

.pg-mypage h3 {
	font-size: 14px;
	margin-bottom: 10px;
}

.pg-mypage ion-button.mypage {
	height: 30px;
	--border-radius: 100px;
	font-size: 14px;
	width: 110px;
	margin-top: 10px;
}

/*====================================MyPageInfo 내 정보 수정====================================*/
.pg-mypage-info ion-grid {
	padding-top: 6px;
}

.pg-mypage-info .chck {
	position: absolute;
	right: 0;
	bottom: 10px;
	font-size: 12px;
	z-index: 100;
	cursor: pointer;
	border: solid 1px #44bbcd;
	color: #44bbcd;
	padding: 2px 8px;
	background: #fff;
	border-radius: 50px;
}

.pg-mypage-info ion-list ion-item {
	margin-top: 14px;
}

.pg-mypage-info ion-list ion-item:first-child {
	margin-top: 0;
}

.pg-mypage-info .change {
	position: absolute;
	right: 0;
	bottom: 10px;
	font-size: 12px;
	z-index: 100;
	cursor: pointer;
	border: solid 1px #44bbcd;
	color: #44bbcd;
	padding: 2px 8px;
	background: #fff;
	border-radius: 50px;
}

.pg-mypage-info .close {
	text-align: right;
	font-size: 12px;
	margin-top: 20px;
	margin-bottom: 30px;
	display: flex;
	justify-content: space-between;
}

.pg-mypage-info .close label {
	font-size: 12px;
	font-weight: bold;
}

.pg-mypage-info .account {
	cursor: pointer;
	color: #777;
	border: solid 1px #777;
	padding: 5px 15px;
	border-radius: 50px;
}

.pg-mypage-info ion-grid ion-col ion-button {
	margin-top: 30px;
	margin-bottom: 30px;
}

.pg-mypage-info h2 {
	font-size: 16px;
}

.pg-mypage-info p {
	font-size: 14px;
	text-indent: -14px;
	padding-left: 14px;
}

.pg-mypage-info .check ion-label {
	font-size: 14px;
	margin: 0;
	font-weight: 400;
}

.pg-mypage-info ion-grid ion-col ion-button.btn-close {
	margin-top: 0px;
}

/*====================================회원탈퇴====================================*/

.pg-myaccount-close ion-grid ion-row:last-of-type ion-item {
	border-top: solid 1px #e0e0e0;
	padding-top: 5px;
	padding-bottom: 5px;
	margin-top: 3px;
}

.pg-myaccount-close ion-grid {
	padding-top: 26px;
}

/*====================================병원찾기====================================*/

.pg-find-clinic ion-segment,
.pg-find-clinic ion-segment ion-segment-button {
	height: 48px;
	min-height: 48px;
}

.pg-find-clinic ion-segment-button {
	--indicator-height: 3px;
	border-bottom: solid 1px #e0e0e0;
	background-color: #fff;
}

.pg-find-clinic .map-marker {
	border-radius: 50px;
	background-color: #fff;
	border: solid 1px #44bbcd;
	display: flex;
	font-size: 14px;
	align-items: center;
}

.pg-find-clinic .map-marker .title {
	background-color: #fff;
	color: #44bbcd;
	border-radius: 50px;
	padding: 5px;
	padding-right: 8px;
	font-weight: 500;
	min-width: 50px;
}

.pg-find-clinic .map-marker .sub-title {
	min-width: 30px;
	background-color: #44bbcd;
	color: #fff;
	border-radius: 50px;
	text-align: center;
	padding: 5px 8px;
	font-weight: 600;
}

ion-modal.my-clinic-modal {
	margin-bottom: calc(70.7px + constant(safe-area-inset-bottom));
	margin-bottom: calc(70.7px + env(safe-area-inset-bottom));
	overflow-y: hidden;
	pointer-events: none;
	--box-shadow: 0px -12px 10px 5px rgba(0, 0, 0, 0.01);
}

ion-modal.my-clinic-modal #ion-react-wrapper {
	pointer-events: auto;
	/* max-width: 480px; */
	margin: auto;
}

ion-modal.my-clinic-modal::part(backdrop) {
	pointer-events: none !important;
	background-color: transparent;
}

ion-modal.my-clinic-modal ion-toolbar {
	--background: #fff;
}

ion-modal.my-clinic-modal ion-label h3 {
	font-size: 16px;
	font-weight: bold;
}

.pg-find-clinic .rescan {
	position: absolute;
	top: 120px;
	overflow: hidden;
	z-index: 1;
	width: 135px;
	left: 50%;
	margin-left: -65px;
	text-align: left;
	border-radius: 50px;
	--min-height: 35px;
	box-shadow: 0px 0px 10px 0 #00000033;
}
.pg-find-clinic .current-location:active,
.pg-find-clinic .current-location:focus {
	background: #e0e0e0;
}
.pg-find-clinic .rescan:active,
.pg-find-clinic .rescan:focus {
	--background: #e0e0e0;
}

.pg-find-clinic .rescan ion-icon {
	margin-left: 10px;
	margin-right: 10px;
	height: 20px;
	width: 20px;
	font-weight: 600;
}

.pg-find-clinic .rescan .item.sc-ion-label-ios-h,
.pg-find-clinic .item .sc-ion-label-ios-h {
	font-size: 14px;
	margin: 0;
}

.pg-find-clinic .current-location {
	position: absolute;
	top: 120px;
	right: 20px;
	overflow: hidden;
	background-color: #fff;
	z-index: 1;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pg-find-clinic .current-location .icon {
	height: 26px;
	width: 26px;
}

.pg-find-clinic .custom_typecontrol {
	overflow: hidden;
	width: 100%;
	height: 30px;
	margin: auto;
	padding: 0;
	z-index: 1;
	border: solid 1px #e0e0e0;
	height: auto;
	cursor: pointer;
	border-radius: 12px;
}

.pg-find-clinic .custom_typecontrol .sc-ion-label-ios-s h3 {
	font-size: 16px;
	font-weight: bold;
}

.pg-find-clinic .map_wrap {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: calc(100% - 104px) !important;
}
.pg-find-clinic .radius_border {
	border: 1px solid #919191;
	border-radius: 5px;
}

.pg-find-clinic .custom_typecontrol .item.sc-ion-label-ios-h,
.pg-find-clinic .custom_typecontrol .sc-ion-label-ios-h {
	margin: 20px;
}

.pg-find-clinic .custom_typecontrol img.icon {
	margin-right: 20px;
}

ion-modal.pg-filter {
	height: 100%;
	--height: 100%;
	max-height: 100%;
}

ion-modal.filter::part(content) {
	background-color: transparent;
}

ion-modal.filter {
	max-height: 100%;
	height: 100%;
	align-items: flex-end;
	top: auto;
	--box-shadow: 0px 15px 25px 0 #0000001a;
	--border-radius: 0;
}

ion-modal.filter #ion-react-wrapper {
	/* max-width: 480px; */
	margin: auto;
	justify-content: flex-end;
}

.clinic-filter-wrap ion-select {
	padding: 0;
	padding-right: 5px;
}

.modal-radio ion-item {
	border-top: solid 1px #e0e0e0;
	font-size: 14px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.modal-title ion-button {
	--background: transparent;
	--background-activated: transparent;
	--background-hover: transparent;
	position: absolute;
	right: 0;
	margin: 0;
}

.modal-title {
	text-align: center;
	padding-top: 5px;
	padding-bottom: 5px;
	border-bottom: solid 1px #e0e0e0;
	background-color: #fff;
}

.modal-title ion-label {
	font-size: 16px;
	font-weight: 500;
}

.pg-filter ion-item {
	font-size: 14px;
	font-weight: 500;
}
.pg-filter ion-label {
	font-size: 12px;
}

.pg-filter ion-grid > ion-row:first-of-type {
	border-bottom: solid 1px #e0e0e0;
	padding-bottom: 20px;
	margin-bottom: 20px;
}
.pg-filter ion-grid ion-button {
	margin-top: 20px;
}
.pg-filter ion-list,
.pg-filter ion-col > ion-item {
	margin-top: 10px;
}
.pg-filter .reset {
	font-size: 16px;
	cursor: pointer;
}
.clinic-filter-wrap .distance .sc-ion-label-ios-h {
	display: none;
}

.clinic-filter-wrap {
	padding-top: 32px;
	padding-right: 20px;
	padding-left: 20px;
	padding-bottom: 12px;
	border-bottom: solid 1px #e0e0e0;
}

.clinic-filter-wrap ion-chip {
	margin-top: 0;
	margin-bottom: 0;
	height: 36px;
	border-color: #e0e0e0;
	border-radius: 60px;
}
.clinic-filter-wrap ion-chip:first-of-type {
	margin-left: 0;
}

.clinic-filter-wrap .distance {
	font-size: 14px;
	color: #282828;
	display: inline-flex;
	flex-direction: column;
	height: 32px;
	justify-content: center;
	border: solid 1px rgba(0, 0, 0, 0.32);
	border-radius: 50px;
	margin-right: 4px;
	margin-left: 4px;
	--color: #282828;
}

.clinic-filter-wrap .distance:hover {
	--background: rgba(0, 0, 0, 0.01);
}

.clinic-filter-wrap .distance ion-select {
	width: 100%;
	max-width: 100%;
	padding: 0;
	padding-left: 12px;
	padding-right: 12px;
}

.pg-find-clinic-detail .sub-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.pg-find-clinic-detail .sub-title.normal {
	flex-direction: row-reverse;
}

.pg-find-clinic-detail h2 {
	font-size: 16px;
	font-weight: 600;
	margin: 5px 0;
}

.pg-find-clinic-detail h3 {
	font-weight: 600;
	margin-bottom: 5px;
	font-size: 20px;
}
.pg-find-clinic-detail h4 {
	color: #282828;
	font-size: 16px;
	font-weight: 600;
}
.pg-find-clinic-detail h5 {
	color: #c6c6c6;
	font-size: 13px;
}

.pg-find-clinic-detail .video-item {
	height: auto;
}

.pg-find-clinic-detail p {
	color: #282828;
	white-space: break-spaces;
	overflow: auto;
}

.pg-find-clinic-detail ion-content > ion-item:nth-of-type(2) {
	border-bottom: solid 1px #e0e0e0;
	padding-bottom: 20px;
}

.pg-find-clinic-detail ion-item.ion-padding {
	padding-top: 6px;
	padding-bottom: 6px;
}

.pg-find-clinic-detail ion-item.location-info {
	padding-bottom: 0px;
}

.pg-find-clinic-detail .swiper-slide img {
	object-fit: cover;
	/* height: 246px; */
	width: 100%;
}

.pg-find-clinic-detail .officeHours-box {
	width: 100%;
	padding: 15px 25px;
	border: solid 1px #e0e0e0;
	border-radius: 12px;
}

.pg-find-clinic-detail .officeHours-box .officeHours-row {
	display: flex;
	border-bottom: solid 1px #e0e0e0;
	justify-content: space-between;
	align-items: center;
	padding-left: 20px;
	padding-right: 30px;
	padding-bottom: 15px;
	padding-top: 15px;
}

.pg-find-clinic-detail .officeHours-box .officeHours-row:first-child {
	padding-top: 0;
}

.pg-find-clinic-detail .officeHours-box .officeHours-row:last-child {
	border-bottom: none;
	padding-bottom: 0;
}

.pg-find-clinic-detail .officeHours-box .officeHours-row label {
	font-size: 14px;
}

.pg-find-clinic-detail .officeHours-box .officeHours-row p {
	font-size: 14px;
	font-weight: 600;
	margin: 0;
	margin-top: 2px;
}

.pg-find-clinic-detail .officeHours-box .officeHours-row:nth-child(3) div:nth-of-type(2),
.pg-find-clinic-detail .officeHours-box .officeHours-row:nth-child(3) div:nth-of-type(2) p {
	color: #5454ff;
}

.pg-find-clinic-detail .officeHours-box .officeHours-row:nth-child(4) div:nth-of-type(1),
.pg-find-clinic-detail .officeHours-box .officeHours-row:nth-child(4) div:nth-of-type(1) p {
	color: red;
}

.pg-find-clinic-detail ion-card {
	box-shadow: none;
	margin: 0;
}

.pg-find-clinic-detail ion-card #map {
	border-radius: 12px;
	margin-bottom: 20px;
}

.pg-find-clinic-detail a img.icon {
	height: 12px;
	width: 12px;
	vertical-align: middle;
}

.clinic-item a span {
	vertical-align: middle;
}

.clinic-item a img.icon {
	height: 12px;
	width: 12px;
	vertical-align: middle;
	padding-top: 1px;
}

.clinic-item h3 {
	font-size: 16px;
	font-weight: 600;
}

ion-chip ion-label.ion-color.sc-ion-label-ios-h {
	color: #282828;
}

.clinic-filter-wrap ion-chip.specilist {
	background-color: #44bbcd;
	border-color: #44bbcd;
}

.clinic-filter-wrap ion-chip.specilist ion-label.ion-color.sc-ion-label-ios-h {
	color: #fff;
}

.noraml {
	background-color: #fff;
}

.noraml ion-label.ion-color.sc-ion-label-ios-h {
	color: #44bbcd;
}

/*====================================검색페이지====================================*/

.pg-search ion-item.chk {
	border-top: solid 1px #e0e0e0;
	border-bottom: solid 1px #e0e0e0;
}
.pg-search ion-item.chk ion-label {
	font-size: 14px;
	color: #777;
}

.pg-search ion-item.chk ion-label span {
	cursor: pointer;
}

.pg-search .list-search-result ion-label {
	font-size: 16px;
	color: #282828;
}

.pg-search ion-label.impt {
	font-weight: 500;
	font-size: 14px;
	color: #777;
}

.pg-search .content-sub {
	font-size: 14px;
	text-align: center;
	padding-top: 50px;
	padding-bottom: 50px;
}

.pg-search .cancel {
	opacity: 0.38;
	cursor: pointer;
}

.pg-search .recent-search ion-label {
	cursor: pointer;
}

.pg-search ion-searchbar ion-buttons {
	position: absolute;
	right: 8px;
}

.pg-search ion-searchbar.sc-ion-searchbar-md-h,
.pg-search ion-searchbar.sc-ion-searchbar-ios-h {
	margin: 0;
}

.pg-search .form-search {
	margin-right: 15px;
}

.pg-search ion-buttons.back {
	margin-top: 9.1px;
	margin-left: 9px;
}
/*====================================북마크====================================*/

.pg-bookmark .title {
	text-align: center;
	color: #777;
	font-size: 20px;
	font-weight: bold;
	padding-top: 70px;
	padding-bottom: 15px;
}

.pg-bookmark .sub-title {
	text-align: center;
	color: #777;
	font-size: 16px;
}

.pg-bookmark ion-button.main-btn {
	margin-top: 80px;
	margin-bottom: 30px;
}
.pg-bookmark ion-segment {
	border-bottom: solid 1px #e0e0e0;
	--background: #fff;
}
.pg-bookmark ion-segment-button {
	--indicator-height: 3px;
}

.pg-bookmark .clinic-item h3 {
	font-size: 16px;
	font-weight: 600;
}

/*====================================공지사항&FAQ 아코디언====================================*/

.pg-notice ion-accordion ion-icon.ion-accordion-toggle-icon,
.pg-faq ion-accordion ion-icon.ion-accordion-toggle-icon {
	width: 18px;
	height: 18px;
	margin-right: 20px;
}
.pg-notice ion-accordion ion-label.ion-padding,
.pg-faq ion-accordion ion-label.ion-padding {
	padding-top: 15px;
	padding-bottom: 15px;
	margin: 0;
	white-space: initial;
}

.pg-notice ion-accordion,
.pg-faq ion-accordion {
	border-bottom: solid 1px #e0e0e0;
}

.pg-notice ion-accordion .header h2,
.pg-faq ion-accordion .header h2 {
	font-size: 16px;
	font-weight: 500;
}

.pg-notice ion-accordion .content p,
.pg-faq ion-accordion .content p {
	font-size: 16px;
	margin-top: 0px;
	background-color: #f8f8f8;
	width: 100%;
	height: 100%;
	margin: 0;
	padding-top: 15px;
	padding-bottom: 15px;
}

/*====================================FAQ====================================*/

.pg-faq ion-segment {
	border-bottom: solid 1px #e0e0e0;
	--background: #fff;
}

.pg-faq ion-segment-button {
	--indicator-height: 3px;
}

.pg-faq .footer {
	width: 100%;
	/* max-width: 480px; */
	bottom: 0;
	text-align: center;
	font-size: 14px;
}

.pg-faq .footer p {
	color: #b5b5b5;
}

.pg-faq .footer span {
	display: block;
	margin-top: 10px;
	color: #b5b5b5;
}

.pg-faq ion-accordion-group {
	padding-bottom: 103.5px;
}

.pg-faq .faq-content {
	padding: 20px;
	background-color: #f8f8f8;
}

.pg-faq .faq-content p {
	padding: 0;
	margin-top: 0;
	margin-bottom: 10px;
	font-size: 15px;
}

/*====================================약관보기====================================*/

.pg-view-terms ion-item {
	border-bottom: solid 1px #e0e0e0;
}
.pg-view-terms .item.sc-ion-label-ios-h,
.pg-view-terms .item .sc-ion-label-ios-h {
	font-size: 16px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.pg-view-terms ion-list .icon {
	margin-right: 20px;
}

.pg-view-terms-detail .content-inner {
	padding: 20px 0;
}

.pg-view-terms-detail p {
	margin: 0;
	font-size: 15px;
}
/*====================================내피부관련문진====================================*/
.pg-survey .title-sub {
	background-color: #e2f4f7;
	font-size: 16px;
	text-align: center;
	margin: 0;
	padding-top: 20px;
	padding-bottom: 20px;
}

.pg-survey .label {
	margin-top: 15px;
	margin-bottom: 15px;
}
.pg-survey .row-btn {
	margin-top: 20px;
	padding-bottom: 20px;
}
.pg-survey h2 {
	text-align: left;
	height: 26px;
	margin-top: 16px;
	margin-bottom: 10px;
}

.pg-survey h2 span {
	text-align: left;
	font-size: 12px;
	cursor: pointer;
	border: solid 1px #44bbcd;
	font-weight: 400;
	color: #44bbcd;
	padding: 2px 8px;
	background: #fff;
	border-radius: 50px;
}

.pg-survey h1 {
	font-size: 18px;
	font-weight: 600;
	text-align: left;
}

.pg-survey h1 span {
	font-size: 12px;
	font-weight: 400;
	color: #777;
	margin-left: 5px;
}

.pg-survey ion-item {
	border-bottom: solid 1px #e0e0e0;
}

.pg-survey ion-row:nth-of-type(2) {
	padding-top: 10px;
}

.pg-survey ion-list ion-item .label {
	font-size: 16px;
	font-weight: 400;
	color: #282828;
}

.pg-survey ion-list ion-item ion-checkbox {
	height: 26px;
	width: 26px;
	margin-right: 10px;
}

/*====================================미디어쿼리====================================*/

@media screen and (max-width: 767px) {
	/*width 768px 이하(모바일) */

	.app .pg-find-clinic .wrap {
		padding-right: 20px;
		padding-left: 20px;
	}

	.pg-mypage ion-list ion-item:first-of-type ion-label p {
		font-size: 14px;
	}

	.home .slide-banner {
		height: 220px;
		margin-bottom: 0;
	}

	.home .slide-banner ion-card {
		height: 160px;
	}

	.pg-faq ion-segment ion-segment-button::part(native) {
		padding-left: 16px;
		padding-right: 16px;
	}
	.home .btn-skin-result .txt-in-btn {
		font-size: 12px;
	}
	.popover {
		--width: var(--popover-width);
		--height: var(--popover-height);
		--offset-x: calc(50vw - var(--mobile-padding-horizontal) - var(--popover-width) / 2) - 20px;
		--offset-y: calc(50vh - var(--tabbar-height) - var(--popover-height) / 2);
	}
}

@media screen and (min-width: 768px) {
	/*width 769px 이상(pc) */
	.app ion-content::part(scroll) {
		padding-inline: var(--pc-padding-horizontal);
	}
	.app .modal-terms ion-content::part(scroll) {
		padding-inline: unset;
	}
	.app .my-clinic-modal ion-content::part(scroll) {
		padding-inline: 0;
	}

	.app .common-header,
	.app .home ion-header,
	.app .pg-find-clinic > ion-segment,
	.app .pg-search ion-header,
	.app ion-footer,
	.app .login-page-no,
	.pg-filter ion-toolbar,
	.app .modal-terms {
		padding-inline: var(--pc-padding-horizontal);
	}

	.pg-login-base .login-wrap {
		width: 480px;
	}

	.app ion-modal.my-clinic-modal,
	.app ion-modal.filter {
		--max-width: 480px;
	}
	.app .pg-bookmark > ion-segment,
	.app .pg-faq > ion-segment,
	.app .pg-skin-info .menu-wrap,
	.app .pg-skin-video .menu-wrap,
	.app .common-tab-bar,
	.current-location {
		margin-inline: var(--pc-padding-horizontal);
	}
	.custom_typecontrol {
		max-width: 440px;
	}

	.app .pg-find-clinic .wrap {
		width: 440px;
	}

	.wrap {
		margin: auto;
	}
	.map_wrap {
		max-width: 480px;
		margin: auto;
	}
	.menu-before {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background-color: #fff;
		line-height: 48px;
		height: 48px;
		padding-right: 10px;
		padding-left: 20px;
		background: #fff;
	}

	.menu-next {
		display: block;
		position: absolute;
		top: 0;
		right: 54px;
		background-color: #fff;
		line-height: 48px;
		height: 48px;
		padding-left: 10px;
		background: #fff;
	}

	.pg-skin-video .menu,
	.pg-skin-info .menu {
		padding-left: 54px;
		padding-right: 10px;
	}

	.app .pg-video-detail .video-thumbnail-wrapper {
		width: 100%;
		/* max-width: 480px; */
		height: 269.53px;
		max-height: 269.53px;
	}

	.app .pg-video-detail .video-thumbnail {
		object-fit: cover;
		height: 100%;
		max-height: 269.53px;
	}

	/* 스크롤에 content 내부 생기는 문제로 내부 width를 고정 */
	.content-inner {
		width: 480px;
	}

	.pg-find-clinic-detail .officeHours-box .officeHours-row {
		padding-right: 70px;
	}

	.popover {
		--width: calc(var(--popover-width) + 40px);
		--height: var(--popover-height);
		--offset-x: calc(50vw - var(--pc-padding-horizontal) - var(--popover-width) / 2) - 48px;
		--offset-y: calc(50vh - var(--tabbar-height) - var(--popover-height) / 3);
	}

	/*alert 디자인*/
	.sc-ion-alert-ios-h {
		--width: 320px;
		--max-width: 320px;
	}
}

@media screen and (min-width: 769px) {
}

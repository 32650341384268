.file-upload {
	&-list {
		.upload-item {
			display: flex;
			align-items: center;

			.name {
				margin: 0;
			}
		}
		.icon-delete {
			width: 24px;
			height: 24px;
			background: url(../../../img/icons/icon-delete.svg) no-repeat;
		}
	}
}

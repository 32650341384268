.thumbnail-slider {
	position: relative;

	.slick-slider {
		position: inherit;
	}
	.slick-arrow {
		top: initial;
		bottom: 10px;
		z-index: 10;
	}
}

.change-slider {
	.slick-slider {
		position: inherit;
	}

	.slick-arrow {
		top: initial;
		bottom: 10px;
		z-index: 10;
	}

	.swiper-slide img {
		width: 100%;
		height: 100%;
	}

	.slick-dots {
		position: inherit;
	}

	.slick-dots li {
		display: flex;
		flex-direction: row;
	}

	.img-area {
		position: relative;
		.toggle {
			position: absolute;
			top: 10px;
			right: 20px;
			ion-toggle[aria-checked="false"]::part(track) {
				background: #e0e0e0;
			}
		}
	}

	.pagination-slider {
		position: relative;
		padding: 7px 0 12px 0;
		background-color: #fff;
		width: 100%;

		.slick-track {
			display: flex;
			justify-content: center;
		}
		.slick-slide {
			width: 80px !important;
			height: 32px;
			margin-right: 8px;
			border: solid 1px #e0e0e0;
			border-radius: 16px;
			line-height: 28px;
			cursor: pointer;

			&:last-child {
				margin: 0;
			}
		}
		.slick-current {
			border: 0;
			background-color: #00bed0;
			.slick-slide-title {
				color: #fff;
			}
		}
		.slick-slide-title {
			margin: 0;
			font-size: 12px;
			font-weight: normal;
			letter-spacing: -0.3px;
			text-align: center;
		}
	}

	.slick-arrow-area {
		.arrow {
			top: 46%;
		}
	}
}

.analysis-slider {
	.slick-dots li a:nth-child(2) {
		display: none;
	}

	.slick-dots li a:nth-child(3) {
		display: none;
	}

	.slick-slide-title {
		height: 56px;
		line-height: 56px;
		margin: 0;
		text-align: center;
	}

	.pagination {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	.pagination a {
		width: 56px;
		height: 56px;
		margin: 0 7px;
	}

	.pagination-slider {
		padding-top: 4px;
		background-color: #fff;

		.slick-track {
			width: calc(100% - 168px) !important;
			display: flex;
			justify-content: center;
		}
		.slick-slide {
			width: 56px !important;
			height: 56px;
			margin: 0 7px;
			opacity: 0.68;
		}
		.slick-slide img {
			width: 56px;
			height: 56px;
			border-radius: 50%;
			object-fit: cover;
		}
		.slick-slide[data-index="3"] {
			display: none;
		}
		.slick-slide.slick-current img {
			border: 2px solid #44bbcd;
		}
		.slick-slide.slick-current {
			opacity: 1;
		}

		.slick-list {
			padding: 0 !important;
		}
	}

	.slick-arrow-area {
		position: relative;
	}
}

.banner-slider {
	position: relative;
	.slick-slide {
		cursor: pointer;
	}
	.slick-dots {
		position: absolute;
		width: 40px;
		height: 20px;
		right: 10px;
		bottom: 10px;
		z-index: 10;
	}
	.slick-dots li {
		display: none;
		margin: 0;
		width: 40px;
		height: 20px;
		border-radius: 10px;
		background-color: #0000005c;
		line-height: 16px;
		text-align: center;
		span {
			display: inline-block;
			font-size: 12px;
			font-weight: 500;
			line-height: 1.42;
			letter-spacing: -0.3px;
			color: #fff;
		}
	}
	.slick-dots .slick-active {
		display: block;
	}
}

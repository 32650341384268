/*
 * File        : question.scss
 * Author      : AI2U (KHS)
 * Description : analysis question page css
*/

.question-contents {
	// question info
	&__info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;

		h2 {
			margin-bottom: 20px;
			font-size: 20px;
			letter-spacing: -0.5px;
		}

		p {
			max-width: 203px;
			font-size: 16px;
			letter-spacing: -0.4px;
		}
	}

	&__bottom {
		h3 {
			font-size: 14px;
			color: #acacac;
			margin-top: 0;
			margin-bottom: 20px;
			text-align: center;
		}
	}

	&__button {
		position: absolute;
		bottom: 30px;
		left: 50%;
		width: 100%;
		transform: translate(-50%, 0);

		button {
			width: 100%;
			padding: 15px 20px;
			background: #ddd;
		}
	}

	&__list {
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 105px;

		// common radio button
		ion-radio-group .item ion-label {
			display: flex;
		}

		ion-radio-group ion-item {
			ion-radio[aria-checked="true"]::part(container) {
				display: none;
			}

			ion-radio {
				margin: 0;
				width: 0;
			}
		}

		ion-radio-group .item-radio-checked .photo-area {
			background: #44bbcd;
		}
		ion-radio-group ion-item.item-radio-checked ion-label {
			background: #44bbcd;
			--background: #44bbcd;
			color: #fff;
		}
		ion-radio-group.radio-type .item-radio-checked ion-label {
			border: 1px solid transparent;
		}
		ion-radio-group .item-radio-checked:hover ion-label {
			color: #fff;
		}
		ion-radio-group .item-radio-checked:hover p {
			color: #fff;
		}

		ion-radio.radio-checked {
			color: #fff;
		}

		ion-radio::part(mark) {
			display: none;
		}

		ion-radio-group ion-item::part(native) {
			min-height: auto;
		}

		// title
		&--title {
			position: fixed;
			padding-inline: var(--pc-padding-horizontal);
			left: 0;
			min-height: 106px;
			max-height: 134px;
			width: 100%;
			z-index: 10;

			.num {
				display: inline-block;
				background-color: #e2f4f7;
				border-radius: 15px;
				padding: 4px 14px 6px 14px;
				font-size: 14px;
				font-weight: 500;
				color: #44bbcd;
				letter-spacing: -0.35px;
			}

			.category {
				max-height: 58px;
				font-size: 20px;
				margin: 16px 0 0 0;
				font-weight: bold;
				letter-spacing: -0.5px;
			}

			.description {
				display: block;
				margin-top: 8px;
				font-size: 14px;
				font-weight: normal;
				letter-spacing: -0.35px;
				color: #acacac;
			}

			.ion-padding {
				padding-bottom: 25px;
				background: #fff;
			}
		}

		&--wrap {
			padding-bottom: 70px;

			// card contents
			.card-contents {
				width: 100%;
				.card-item {
					width: 100%;
					padding-top: 30px;
				}
				.card-item .photo-area {
					padding: 20px 16px;
				}

				.list-wrap {
					width: calc(100% / 2 - 8px);
					padding: 0;
				}
				.list-wrap ion-item {
					width: 100%;
					min-height: 160px;
					display: flex;
					align-items: center;
					justify-content: center;
					--padding-start: 0;
				}
				.list-wrap .item-checkbox-checked {
					border: 1px solid transparent;
				}
				.list-wrap .none ion-label {
					min-height: 160px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.list-wrap ion-item img {
					width: 100%;
					border-radius: 8px;
				}
				.list-wrap .label-text {
					margin: 0;
					padding: 9px 0 20px 0;
				}

				.photo-card .item-checkbox-checked {
					background: #44bbcd;
					--background: #44bbcd;
				}
				.photo-card .photo-img {
					padding: 8px 8px 0 8px;
				}
				// .photo-card ion-item::part(native) {
				// 	min-height: 200px;
				// }
				.photo-card .label-none {
					padding: 87px 0 89px 0;
				}

				.ox-type {
					padding-top: 10px;
				}

				.title {
					text-align: left;
					font-weight: bold;
				}
				.info {
					margin-top: 8px;
					line-height: normal;
					font-weight: 500;
					text-align: left;
					white-space: initial;
					color: #282828;
				}

				ion-card {
					width: 100%;
					margin: 0;
					box-shadow: none;
					background: transparent;
				}
				ion-item {
					border: solid 1px #e0e0e0;
					border-radius: 16px;
					--border-radius: 16px;
				}

				.item-padding {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					width: 100%;
				}

				.item-radio-checked ion-label p {
					color: #fff;
				}
				.item-radio-checked ion-label span {
					color: #fff;
				}

				ion-checkbox {
					margin: 0;
					height: 0;
				}
				ion-card-content {
					text-align: center;
					padding: 0;

					p {
						font-size: 16px;
						font-weight: 500;
						letter-spacing: -0.4px;
						// padding: 12px 0 0 0;
					}

					span {
						display: block;
						font-size: 14px;
						letter-spacing: -0.35px;
					}
				}

				ion-item::part(native) {
					// padding: 8px 8px 20px 8px;
					flex-direction: column;
				}

				ion-checkbox::part(container) {
					display: none;
					--background-hover: transparent;
				}

				ion-radio-group {
					width: 100%;
				}

				.item-radio-checked {
					border: 1px solid transparent;
				}

				// photo list
				.list-photo {
					width: 100%;
					margin-bottom: 16px;
					min-height: 160px;

					.photo-radio {
						height: 0;
					}

					ion-label {
						display: block;
						width: 100%;
						padding: 12px 16px 0 16px;
						margin: 0;
					}

					.photo-area {
						display: flex;
						flex-direction: row;
						justify-content: space-between;

						img {
							height: 100%;
							width: calc(100% / 3 - 12px);
							margin-right: 16px;
							border-radius: 8px;
						}
						img:last-child {
							margin: 0;
						}
					}

					.photo-type {
						border: 0;
						max-height: fit-content;

						.photo-area {
							padding: 0;
						}
					}
				}

				.label-none {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0;
					min-height: 160px;
					font-weight: 500;
				}
			}

			// checkbox contents/List05
			.checkbox-contents {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.list-wrap {
					width: calc(100% / 2);
					padding: 0;
					margin: 0;
				}

				ion-checkbox {
					margin-top: 0;
					margin-right: 10px;
				}
				ion-checkbox::part(container) {
					width: 24px;
					height: 24px;
					border-color: #ebebeb;
				}

				.item-checkbox-checked {
					ion-checkbox::part(container) {
						border: 1px solid transparent;
					}
				}
				ion-label {
					padding-top: 10px;
				}
			}

			.sub-text {
				display: block;
			}
		}

		&--item {
			padding-top: 30px;
		}

		&--item.item-padding {
			padding-top: 50px;
		}
		&--item {
			.item-line .ox-type {
				height: 68px;
			}
			.item-line .ox-type ion-radio-group {
				width: auto;
			}

			.item-line::after {
				content: "";
				display: block;
				width: 100%;
				height: 1px;
				background: #e0e0e0;
				margin: 30px 0;
			}

			.item-line:last-child::after {
				display: none;
			}
		}
		&--item.item-double {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			ion-row {
				display: flex;
				width: calc(100% / 2 - 8px);
			}
		}

		// radio type
		.radio-type {
			ion-item {
				// height: 48px;
				--min-height: 48px;
				margin-bottom: 16px;
				// border: 1px solid #e0e0e0;
				// border-radius: 24px;
				text-align: center;
				--background-hover: transparent;
				--transition: 0;
				--background-activated: transparent;
				--border-radius: 24px;
			}
			ion-item ion-label {
				// height: 48px;
				// --min-height: 48px;
				align-items: center;
				justify-content: center;
				margin: 0;
				border: 1px solid #e0e0e0;
				border-radius: 24px;
				font-weight: 500;
				line-height: 48px;
			}
		}

		// radio type2
		.type2 {
			display: flex;

			.radio {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				background: #e0e0e0;
			}
			ion-checkbox:part(mark) {
				opacity: 0;
				display: none;
			}

			.radio-ok {
				margin-right: 20px;
			}
			.radio-ok.radio-checked {
				background: #44bbcd;
			}

			.radio-ok::part(container) {
				background: url("../img/icons/icon-ok.svg") center center no-repeat;
			}

			.radio-ok::part(container) path {
				display: none;
			}

			.radio-no::part(container) {
				background: url("../img/icons/icon-no.svg") center center no-repeat;
			}
			.radio-no.radio-checked {
				background: #ff7473;
			}
			.radio-no.radio-checked::part(mark) {
				display: none;
			}
		}

		// radio type3
		.type3 {
			ion-item {
				height: 72px;
				border-radius: 36px;
			}
			ion-item ion-label {
				flex-direction: column;
				height: 72px;
			}
			ion-item ion-label span {
				line-height: 1.43;
				letter-spacing: -0.35px;
				font-size: 14px;
				color: #acacac;
			}
		}

		// list type
		.list-wrap {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 16px;
			padding: 18px 0;

			ion-card-content p {
				line-height: 1.25;
			}

			.item-checkbox-checked .photo-area {
				background: #44bbcd;
			}
			.item-checkbox-checked ion-label {
				background: #44bbcd;
				--background: #44bbcd;
				--border-radius: 36px;
			}
			.item-checkbox-checked p {
				color: #fff;
			}
			.item-checkbox-checked span {
				color: #fff;
			}

			ion-label {
				font-size: 16px;
				font-weight: 500;
				line-height: 1.5;
				letter-spacing: -0.4px;
			}
			ion-label p {
				font-size: 16px;
				font-weight: 500;
				letter-spacing: -0.4px;
				line-height: 1.25;
				color: #282828;
			}
			ion-label span {
				display: block;
				font-size: 14px;
				letter-spacing: -0.35px;
				color: #acacac;
			}

			ion-item {
				--background-hover: transparent;
				--background-activated-opacity: 0;
			}

			ion-checkbox svg:part(container) {
				display: none;
			}
		}

		// ox list
		.list-wrap.ox-type {
			max-height: 60px;
			width: 100%;
		}

		// multiple check
		.multiple-check {
			padding: 0;
			ion-item {
				width: 100%;
				// border: 1px solid #e0e0e0;
				// border-radius: 36px;
				min-height: 48px;
				--min-height: 48px;
			}

			ion-radio-group {
				width: 100%;
			}

			.lines-item {
				// max-height: 72px;

				ion-label {
					height: 72px;
					min-height: 48px;
					max-height: 72px;
					white-space: initial;
				}

				.sub-none {
					height: 48px;
				}
			}

			ion-checkbox {
				margin: 0;
				--border-color: transparent;
				--background: transparent;
				--size: 0;
			}
			ion-label {
				margin: 0;
				text-align: center;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border: 1px solid #e0e0e0;
				border-radius: 36px;
				padding: 0 31px;
				line-height: 1.25;
			}

			.item-checkbox-checked ion-label {
				border: 1px solid transparent;
			}
		}

		.multiple-check.hide {
			display: none;
		}
	}

	&__list.list {
		padding-top: 135px;
	}

	// summary
	&__summary {
		.info {
			width: 100%;
			padding: 10px 16px 10px 16px;
			background-color: #e2f4f7;
			border-radius: 8px;
		}

		.info p {
			margin: 0;
			display: flex;
			font-size: 16px;
			color: #44bbcd;
			letter-spacing: -0.4px;
			line-height: 1.6;

			&::before {
				content: "";
				display: inline-block;
				background: url(../img/icons/icon-notice.svg) center center no-repeat;
				width: 25px;
				height: 20px;
				margin-top: 2px;
				margin-right: 10px;
			}
		}

		&--list {
			padding: 30px 0;
			border-bottom: 1px solid #e0e0e0;

			ion-item {
				--min-height: auto;
			}

			.summary-text {
				display: flex;
			}
			.summary-text p {
				margin-top: 0;
				margin-bottom: 0;
				font-size: 16px;
				letter-spacing: -0.4px;
				word-break: keep-all;
				color: #282828;
			}
			.summary-text__title {
				width: 100px;
				margin-right: 10px;
			}
			.summary-text__info {
				display: flex;
				flex-wrap: wrap;
				width: calc(100% - 110px);
			}

			.question {
				font-weight: bold;
			}
			.answer {
				font-weight: 500;

				&::after {
					display: inline-block;
					content: "/";
					margin: 0 3px;
				}
			}
			.answer:last-child::after {
				display: none;
			}
		}
	}
}

// media query (pc)
@media screen and (min-width: 768px) {
	.question-contents__list--wrap .card-contents .photo-card ion-item {
		height: 260px;
	}
}

// mobile
@media screen and (max-width: 767px) {
	.question-contents__list .ox-type ion-label {
		max-width: 210px;
	}

	.question-contents__list--item.padding {
		padding-top: 50px;
	}
}

@media screen and (max-width: 414px) {
	.question-contents__list--wrap .card-contents .photo-card ion-item {
		max-height: 223px;
		min-height: 211px;
	}
}

@media screen and (max-width: 320px) {
	.question-contents__list--wrap .card-contents .photo-card ion-item {
		max-height: 176px;
		min-height: 156px;
	}
}

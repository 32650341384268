.camera {
	height: calc(100% - 160px);
	position: relative;

	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.photo {
	width: 100%;
	height: 100%;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.dim {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	opacity: 0.7;
	background-color: #000;
}
